import aes256 from 'aes256';
import crypto from 'crypto';

const key = 'itawraaqkey';

const cipher = aes256.createCipher(key);
const cryptkey = crypto.createHash('sha256').update('Awraaq!').digest();
const iv = new Buffer.from('a2xhcgAAAAAAAAAA');

export const getEncryptId = (id = 0) => {
    return encodeURIComponent(cipher.encrypt(id.toString()));
}

export const getDecryptId = (id = 0) => {
    return cipher.decrypt(decodeURIComponent(id));
}

export const getEncrypt = (cleardata) => {
    const encipher = crypto.createCipheriv('aes-256-cbc', cryptkey, iv);
    const buf = Buffer.concat([
        encipher.update(cleardata),
        encipher.final()
    ]);
    return buf;
}

export const getDecrypt = (encryptdata) => {
    var decipher = crypto.createDecipheriv('aes-256-cbc', cryptkey, iv);
    const buf = Buffer.concat([
        decipher.update(encryptdata),
        decipher.final()
    ]);
    return buf;
}

export const checkDataIsValid = (data) => {
    if(data !== null && data !== undefined && data !== ''){
        return true;
    }
    return false;
}

export const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
}

export const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export const union = (a, b) => {
    return [...a, ...not(b, a)];
}