import axios from "axios";
import { toast } from "react-toastify";

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_FIX_ADAPTER_API_ENDPOINT,
  REACT_APP_FIX_ADAPTER_TOKEN,
} = process.env;

const GuestApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
});

let accessToken = null;
if (
  localStorage.getItem("user_data") != null ||
  localStorage.getItem("client_data") != null
) {
  const userLocalStorage = JSON.parse(localStorage.getItem("user_data"));
  const clientLocalStorage = JSON.parse(localStorage.getItem("client_data"));
  const { access_token } =
    (userLocalStorage && userLocalStorage?.token) ||
    (clientLocalStorage && clientLocalStorage.token);
  accessToken = access_token;
}
// console.log(accessToken, ".............PI TROKENSJJ");
const AuthApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `bearer ${accessToken}`,
  },
});

AuthApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      toast("Session expired.. Login to continue.");
    }

    return Promise.reject(error);
  }
);

//For Otp
let otpToken = null;
if (localStorage.getItem("otp_login_data") != null) {
  otpToken = localStorage.getItem("otp_login_data");
}

const OtpAuthApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `bearer ${otpToken}`,
  },
});

OtpAuthApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      toast("Session expired.. Login to continue.");
    }
    return Promise.reject(error);
  }
);

//For Admin Otp
let otpAdminToken = null;
if (localStorage.getItem("otp_adminlogin_data") != null) {
  otpAdminToken = localStorage.getItem("otp_adminlogin_data");
}

const OtpAdminAuthApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `bearer ${otpAdminToken}`,
  },
});

OtpAdminAuthApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      toast("Session expired.. Login to continue.");
    }
    return Promise.reject(error);
  }
);

let accessClientToken = null;
if (localStorage.getItem("client_data") != null) {
  const clientLocalStorage = JSON.parse(localStorage.getItem("client_data"));
  const { access_token } = clientLocalStorage?.token;
  accessClientToken = access_token;
}

const AuthClientApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `bearer ${accessClientToken}`,
  },
});

AuthClientApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      toast("Session expired.. Login to continue.");
    }
    return Promise.reject(error);
  }
);

const FixAdapterApi = axios.create({
  baseURL: REACT_APP_FIX_ADAPTER_API_ENDPOINT,
  headers: {
    "X-FixSol-API-KEY": `${REACT_APP_FIX_ADAPTER_TOKEN}`,
  },
});

FixAdapterApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      toast("Session expired.. Login to continue.");
    }
    return Promise.reject(error);
  }
);

export {
  GuestApi,
  AuthApi,
  OtpAuthApi,
  OtpAdminAuthApi,
  REACT_APP_API_ENDPOINT,
  AuthClientApi,
  FixAdapterApi,
};
