export default function validateContact(values) {
    let errors = {};
    if (!values.name) {
        errors.name = "Name is required";
    }
    if (!values.email) {
        errors.email = "Email is required";
    }
    // if (!values.cv) {
    //     errors.cv = "Cv is required";
    // }
    return errors;
}