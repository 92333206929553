import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { GuestApi } from "../../../utils/api";
import useForm from "../../../useForm";
import validate from "../../../validate/validateLogin";
import { apiurl } from "../../../utils/apiurl";
import { toast } from "react-toastify";

export default function Login(props) {
  const formInputObj = { email: "", password: "" };
  const submit = async () => {
    try {
      localStorage.clear();
      var saveResponse = await GuestApi.post(apiurl.client.login, values);
      const { status, data } = saveResponse;
      if (status === 200 && data.otptoken !== undefined) {
        localStorage.setItem("otp_login_data", `${data.otptoken}`);
        props.history.push("/otp-verification");
        window.location.reload();
      }
    } catch (e) {
      const { response } = e;
      if (
        response !== undefined &&
        Object.keys(response.data).length &&
        response.data.error !== undefined
      ) {
        setErrors(response.data.error); // if error from server side
      } else {
        console.log(e);
        toast.error(`😱 Axios request failed: ${e}`);
      }
    }
  };

  const { handleChange, handleSubmit, values, errors, setErrors, setValues } =
    useForm(submit, validate, formInputObj);
  return (
    <>
      <section className="login-page">
        <Container fluid className="px-0">
          <Row className="no-gutters">
            <Col
              xs={12}
              md={12}
              lg={12}
              className="login-form d-flex align-items-center justify-content-center"
            >
              <div className="login-form-sub">
                {/* <h1 className="fs-38 lato font-weight-bold mb-4">
                  Welcome to AWRAAQ
                </h1> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "40px",
                  }}
                >
                  <img
                    src="/imgs/logo-awraaq.png"
                    alt=""
                    style={{
                      width: "300px",
                      height: "auto",
                    }}
                  />
                </div>

                <p className="fs-14 mb-4">
                  Enter username and password to Log in
                </p>
                <LoginForm
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  setValues={setValues}
                  values={values}
                  errors={errors}
                />
              </div>
            </Col>
            {/* <Col
              xs={12}
              md={12}
              lg={6}
              className="right-img d-flex align-items-center justify-content-center"
            >
              <div className="text-center">
                <h2 className="white  lato hind-b fs-38 mb-3">
                  Expand your Brokerage
                </h2>
                <h2 className="white lato hind-b fs-38 mb-3">
                  Network with AWRAAQ
                </h2>
                <p className="white fs-16 mb-4">
                  Click here to register with us
                </p>
                <Button
                  href="/#/register"
                  variant="contained"
                  color="primary"
                  className="orange-btn-mui px-5 mb-0"
                >
                  Sign Up
                </Button>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
}
