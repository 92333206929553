import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import NavbarMenu from '../../../Components/NavbarMenu';
import Footer from '../../../Components/Footer';
import CareerForm from './CareerForm';
import { useTranslation } from 'react-i18next';

export default function Careers() {

    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className={`p-0 career-bannerE${curLang === 'ar' ? 'Ar' : 'n'}`}>
                    <div className="hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`green ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase`}>{t('careerpage.title')}</h1>
                                <p className={`${curLang === 'ar' ? 'almarai' : 'montserrat'} green fs-30 mb-0 font-weight-bold`}>{t('careerpage.title-p')}</p>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>

            <section className="careers py-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {t('careerpage.body-h2')}
                            </h2>
                            <p className="text-black lh-28 fw-medium fs-16 mb-3">{t('careerpage.body-p1')}</p>
                        </Col>
                        <Col xs={12} md={9} lg={7} className="mx-auto" >
                            <Card className="contact mt-5">
                                <Card.Body className="p-4">
                                    <CareerForm />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </div>
    )
}
