import React, {useContext} from 'react';
import { Col } from 'react-bootstrap';
import ForgotPassForm from './ForgotPassForm';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../UserContext';

export default function ForgotPassword() {
    const { user } = useContext(UserContext);
    if (user !== null) return <Redirect to='/admin/dashboard' />;

    return (
        <>
            <section className="login d-flex justify-content-center align-items-center w-100 px-3">   
                <Col xs={12} md={6} lg={4} className="login-wrapper">
                    <h1 className="fs-38 lato font-weight-bold mb-4">Forgot Password</h1>
                    <p className="fs-14 mb-4">Enter your email id to get an OTP....</p>
                    <ForgotPassForm />
                </Col>            
            </section>
        </>
    )
}
