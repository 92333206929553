import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import awraaq from '../assets/img/awraaq2.png';
import Arrow from '../assets/img/arrow-white.png';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();
    const [shown, setShown] = useState(false);

    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <>
            <section className="legal-identity py-4">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`fs-32 white font-weight-bold raleway mt-4 mb-4 font-${curLang}`}>
                                {t('homepage.disclaimer')}
                            </h2> 
                            <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p-1')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p-2')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p-3')}.</p>
                            <div 
                                id="ContentWrapper"
                                className={`${shown ? 'show-content' : 'hide-content'}`}
                            >
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p1-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p1-2')}.</p>
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p2-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p2-2')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>.{t('homepage.disclaimer-p2-3')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p2-4')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p2-5')}.</p>
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p3-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p3-2')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p3-3')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p3-4')}.</p>
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p4-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>.</p>
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p5-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p5-2')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a> {t('homepage.disclaimer-p5-3')}.</p>
                                <p className="fs-16 lh-28 white fw-medium">{t('homepage.disclaimer-p6-1')}<a href="https://www.awraaq.com/" className="white">awraaq.com</a>{t('homepage.disclaimer-p6-2')} <a href="https://www.awraaq.com/" className="white">awraaq.com</a>.</p>
                            </div>
                            {
                                !shown ? <div className="text-center mt-4"><button className="p-0 border-0 bg-transparent fs-16 white fw-medium" onClick={() => setShown(!shown)}>{t('homepage.read-more')} <img src={Arrow} alt="Arrow" className="img-fluid ml-2" /></button></div> : <div className="text-center mt-4"><button className="p-0 border-0 bg-transparent fs-16 white fw-medium" onClick={() => setShown(!shown)}>{t('homepage.read-less')} <img src={Arrow} alt="Arrow" className="img-fluid ml-2 readlessarrow" /></button></div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>

            <footer>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="d-sm-flex justify-content-sm-between align-items-sm-center link-main mt-4 py-4">


                            {/** -------------Esraa---------------- */}
{/** 
                            <Row>

                            <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-left">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    

                                        <Link class="css-footer-color"  to="/privacy-policy">
                                            {t('homepage.privacy-policy')}
                                        </Link>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} lg={4}className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                                <Link class="css-footer-color"  to="/terms-and-condition">
                                            {t('homepage.terms')}
                                        </Link>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} lg={4}className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                                        <Link class="css-footer-color"  to="/Risks">
                                            {t('homepage.risks')}
                                        </Link>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                                <Link class="css-footer-color" to="/privacy-policy-app">
                                            {t('homepage.privacy-policy-app')}
                                        </Link>
                                </div>
                            </div>
                        </Col>


                        <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                               
                                        <Link class="css-footer-color"  to="/terms-and-condition-app">
                                            {t('homepage.termsapp')}
                                        </Link>
                                </div>
                            </div>
                        </Col>


                        <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                                <Link class="css-footer-color"  to="/faq">
                                            {t('homepage.faq')}
                                        </Link>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                    <div class="css-footer">
                                <div class="css-1syxrhr">
                                    
                                <Link class="css-footer-color" to="/careers">
                                            {t('homepage.careers')}
                                        </Link>
                                </div>
                            </div>
                        </Col>


                        </Row>
*/}
                     {/**----------End Esraa------------ */}
                     {curLang === 'ar' ? 

                            <ul className="list-inline mb-sm-0 links" style={{paddingRight: 0, display: "flex"}}>
                                                    
                            <li className="list-inline-item liAr">
                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/terms-and-condition">
                                    {t('homepage.terms')}
                                </Link>
                                </ul>

                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/terms-and-condition-app">
                                    {t('homepage.termsapp')}
                                </Link>
                                </ul>
                            </li>


                            <li className="list-inline-item liAr">
                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/privacy-policy">
                                    {t('homepage.privacy-policy')}
                                </Link>
                                </ul>
                                
                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/privacy-policy-app">
                                    {t('homepage.privacy-policy-app')}
                                </Link>
                                </ul>
                            </li>

                            <li className="list-inline-item liAr">
                            <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/Risks">
                                    {t('homepage.risks')}
                                </Link>
                                </ul>

                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <Link style={{padding: "0px"}} to="/faq">
                                    {t('homepage.faq')}
                                </Link>
                                </ul>
                            </li>

                            
                            <li  className="list-inline-item">

                                <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                <a href="https://mobile.twitter.com/AwraaqDigital" target="_blank" rel="noreferrer noopener">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.274" viewBox="0 0 20 16.274"><path d="M40.1,65.928a8.3,8.3,0,0,1-2.356.648,4.118,4.118,0,0,0,1.805-2.272,8.153,8.153,0,0,1-2.606,1,4.1,4.1,0,0,0-7.1,2.806,4.017,4.017,0,0,0,.106.937A11.628,11.628,0,0,1,21.49,64.75a4.111,4.111,0,0,0,1.276,5.484,4.008,4.008,0,0,1-1.865-.513v.051A4.107,4.107,0,0,0,24.194,73.8a4.131,4.131,0,0,1-1.081.144,3.871,3.871,0,0,1-.771-.076,4.11,4.11,0,0,0,3.836,2.852,8.227,8.227,0,0,1-5.1,1.759,8.317,8.317,0,0,1-.979-.059,11.469,11.469,0,0,0,6.281,1.856A11.605,11.605,0,0,0,38.066,68.586c0-.178,0-.356-.013-.53A8.353,8.353,0,0,0,40.1,65.928Z" transform="translate(-20.1 -64)"/>
                                    
                                </svg>
                                </a>

                                    </ul>


                                    <ul style={{marginBottom: "10px", paddingRight: "0px"  }} >
                                    <a href="https://www.linkedin.com/company/awraaq" target="_blank" rel="noreferrer noopener">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5.444,3.222A2.1,2.1,0,0,1,3.222,5.444,2.137,2.137,0,0,1,1,3.333,2.253,2.253,0,0,1,3.222,1,2.16,2.16,0,0,1,5.444,3.222ZM1,21H5.444V6.556H1ZM16.111,6.778A4.866,4.866,0,0,0,11.889,9h-.111l-.222-1.889h-4c0,1.222.111,2.667.111,4.333V21h4.444V13.111A3.425,3.425,0,0,1,12.222,12a2.39,2.39,0,0,1,2.111-1.778c1.556,0,2.222,1.333,2.222,3.111V21H21V12.778c0-4.111-2.111-6-4.889-6Z" transform="translate(-1 -1)"/></svg>
                                </a>
                                    </ul>
                                    

                            </li>

                            </ul>

                    :

                     <ul className="list-inline mb-sm-0 links" style={{paddingRight: 0, display: "flex"}}>
                         
                                    <li  className="list-inline-item">
                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/terms-and-condition">
                                            {t('homepage.terms')}
                                        </Link>
                                        </ul>

                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/terms-and-condition-app">
                                            {t('homepage.termsapp')}
                                        </Link>
                                        </ul>
                                    </li>

                                    
                                    <li  className="list-inline-item">
                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/privacy-policy">
                                            {t('homepage.privacy-policy')}
                                        </Link>
                                        </ul>
                                        
                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/privacy-policy-app">
                                            {t('homepage.privacy-policy-app')}
                                        </Link>
                                        </ul>
                                    </li>

                                    <li  className="list-inline-item">

                                    <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/Risks">
                                            {t('homepage.risks')}
                                        </Link>
                                        </ul>


                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <Link style={{padding: "0px"}} to="/faq">
                                            {t('homepage.faq')}
                                        </Link>
                                        </ul>
                                        
                    
                                    </li>

                                    <li  className="list-inline-item">

                                        <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                        <a href="https://mobile.twitter.com/AwraaqDigital" target="_blank" rel="noreferrer noopener">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.274" viewBox="0 0 20 16.274"><path d="M40.1,65.928a8.3,8.3,0,0,1-2.356.648,4.118,4.118,0,0,0,1.805-2.272,8.153,8.153,0,0,1-2.606,1,4.1,4.1,0,0,0-7.1,2.806,4.017,4.017,0,0,0,.106.937A11.628,11.628,0,0,1,21.49,64.75a4.111,4.111,0,0,0,1.276,5.484,4.008,4.008,0,0,1-1.865-.513v.051A4.107,4.107,0,0,0,24.194,73.8a4.131,4.131,0,0,1-1.081.144,3.871,3.871,0,0,1-.771-.076,4.11,4.11,0,0,0,3.836,2.852,8.227,8.227,0,0,1-5.1,1.759,8.317,8.317,0,0,1-.979-.059,11.469,11.469,0,0,0,6.281,1.856A11.605,11.605,0,0,0,38.066,68.586c0-.178,0-.356-.013-.53A8.353,8.353,0,0,0,40.1,65.928Z" transform="translate(-20.1 -64)"/>
                                    
                                </svg>
                                </a>
                                        
                                            </ul>


                                            <ul style={{marginBottom: "10px", paddingLeft: "0px"  }} >
                                            <a href="https://www.linkedin.com/company/awraaq" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5.444,3.222A2.1,2.1,0,0,1,3.222,5.444,2.137,2.137,0,0,1,1,3.333,2.253,2.253,0,0,1,3.222,1,2.16,2.16,0,0,1,5.444,3.222ZM1,21H5.444V6.556H1ZM16.111,6.778A4.866,4.866,0,0,0,11.889,9h-.111l-.222-1.889h-4c0,1.222.111,2.667.111,4.333V21h4.444V13.111A3.425,3.425,0,0,1,12.222,12a2.39,2.39,0,0,1,2.111-1.778c1.556,0,2.222,1.333,2.222,3.111V21H21V12.778c0-4.111-2.111-6-4.889-6Z" transform="translate(-1 -1)"/></svg>
                                        </a>
                                            </ul>
                                            
                                    
                                    </li>

                                    
                                    
                                   
                                </ul>
                            }
                   
                     {/** 

                                <ul className="list-inline mb-sm-0 links" style={{paddingRight: 0, textAlign: "center"}}>
                                    <li style={{marginBottom: "20px" }} className="list-inline-item">
                                        <Link to="/faq">
                                            {t('homepage.faq')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/terms-and-condition">
                                            {t('homepage.terms')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/terms-and-condition-app">
                                            {t('homepage.termsapp')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/Risks">
                                            {t('homepage.risks')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/privacy-policy">
                                            {t('homepage.privacy-policy')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/privacy-policy-app">
                                            {t('homepage.privacy-policy-app')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/careers">
                                            {t('homepage.careers')}
                                        </Link>
                                    </li>
                                </ul>
                                */}

                            {/** 
                                <ul className="list-inline mb-0 social mr-sm-2 mr-md-4" style={{paddingRight: 0}}>
                                    <li className="list-inline-item">
                                        <Link to="/" target="_blank" className="align-middle" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.274" viewBox="0 0 20 16.274"><path d="M40.1,65.928a8.3,8.3,0,0,1-2.356.648,4.118,4.118,0,0,0,1.805-2.272,8.153,8.153,0,0,1-2.606,1,4.1,4.1,0,0,0-7.1,2.806,4.017,4.017,0,0,0,.106.937A11.628,11.628,0,0,1,21.49,64.75a4.111,4.111,0,0,0,1.276,5.484,4.008,4.008,0,0,1-1.865-.513v.051A4.107,4.107,0,0,0,24.194,73.8a4.131,4.131,0,0,1-1.081.144,3.871,3.871,0,0,1-.771-.076,4.11,4.11,0,0,0,3.836,2.852,8.227,8.227,0,0,1-5.1,1.759,8.317,8.317,0,0,1-.979-.059,11.469,11.469,0,0,0,6.281,1.856A11.605,11.605,0,0,0,38.066,68.586c0-.178,0-.356-.013-.53A8.353,8.353,0,0,0,40.1,65.928Z" transform="translate(-20.1 -64)"/></svg>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item" style={{marginRight: 24}}>
                                        <a href="https://www.linkedin.com/company/awraaq" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5.444,3.222A2.1,2.1,0,0,1,3.222,5.444,2.137,2.137,0,0,1,1,3.333,2.253,2.253,0,0,1,3.222,1,2.16,2.16,0,0,1,5.444,3.222ZM1,21H5.444V6.556H1ZM16.111,6.778A4.866,4.866,0,0,0,11.889,9h-.111l-.222-1.889h-4c0,1.222.111,2.667.111,4.333V21h4.444V13.111A3.425,3.425,0,0,1,12.222,12a2.39,2.39,0,0,1,2.111-1.778c1.556,0,2.222,1.333,2.222,3.111V21H21V12.778c0-4.111-2.111-6-4.889-6Z" transform="translate(-1 -1)"/></svg>
                                        </a>
                                    </li>
                                </ul> */}
                            </div>

                            <div className="d-md-flex justify-content-md-between align-items-md-center py-4 px-md-4 text-center text-md-left">
                                <Link to="/"><img src={awraaq} alt="Awraaq" className="img-fluid"  style={{width: 219}}/></Link>
                                                         
                                                            {/*Esraa Edit------- */}

                               {/**  <ul className="list-inline mb-0 social mr-sm-2 mr-md-4" style={{paddingRight: 0}}>
                                    <li className="list-inline-item">
                                        <Link to="/" target="_blank" className="align-middle" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.274" viewBox="0 0 20 16.274"><path d="M40.1,65.928a8.3,8.3,0,0,1-2.356.648,4.118,4.118,0,0,0,1.805-2.272,8.153,8.153,0,0,1-2.606,1,4.1,4.1,0,0,0-7.1,2.806,4.017,4.017,0,0,0,.106.937A11.628,11.628,0,0,1,21.49,64.75a4.111,4.111,0,0,0,1.276,5.484,4.008,4.008,0,0,1-1.865-.513v.051A4.107,4.107,0,0,0,24.194,73.8a4.131,4.131,0,0,1-1.081.144,3.871,3.871,0,0,1-.771-.076,4.11,4.11,0,0,0,3.836,2.852,8.227,8.227,0,0,1-5.1,1.759,8.317,8.317,0,0,1-.979-.059,11.469,11.469,0,0,0,6.281,1.856A11.605,11.605,0,0,0,38.066,68.586c0-.178,0-.356-.013-.53A8.353,8.353,0,0,0,40.1,65.928Z" transform="translate(-20.1 -64)"/></svg>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item" style={{marginRight: 24}}>
                                        <a href="https://www.linkedin.com/company/awraaq" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M5.444,3.222A2.1,2.1,0,0,1,3.222,5.444,2.137,2.137,0,0,1,1,3.333,2.253,2.253,0,0,1,3.222,1,2.16,2.16,0,0,1,5.444,3.222ZM1,21H5.444V6.556H1ZM16.111,6.778A4.866,4.866,0,0,0,11.889,9h-.111l-.222-1.889h-4c0,1.222.111,2.667.111,4.333V21h4.444V13.111A3.425,3.425,0,0,1,12.222,12a2.39,2.39,0,0,1,2.111-1.778c1.556,0,2.222,1.333,2.222,3.111V21H21V12.778c0-4.111-2.111-6-4.889-6Z" transform="translate(-1 -1)"/></svg>
                                        </a>
                                    </li>
                                </ul> 
                                */}

                                                            {/*-------------------------- End Esraa Edit */}

                                <p className="fs-16 text-black mt-3 mt-md-0 mb-0">&copy; {t('homepage.foot-p')}</p>
                                
                            </div>

                        </Col>
                    </Row>
                </Container>
            </footer>  
        </>
    )
}
