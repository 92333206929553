import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavbarMenu from '../../../Components/NavbarMenu';
import Footer from '../../../Components/Footer';
import ContactForm from './ContactForm';
import Address from './Address';
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className="p-0 contact-bannerE">
                    <div className="hero contact-hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`apache ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase text-uppercase`}>{t('contactuspage.title')}</h1>
                                <p className={`white fs-30 ${curLang === 'ar' ? 'almarai' : 'montserrat'} mb-0 font-weight-bold text-capitalize`}>{t('contactuspage.title-p')}</p>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>

            <section className="contact py-5">
                <Container>
                    <Row>
                        <Col lg={8} className="d-flex">
                            <div className="contact-details-wrapper">
                                <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-5`}>
                                    {t('contactuspage.title-caption')}
                                </h2>
                                <ContactForm />

                            </div>
                        </Col>
                        <Col lg={4} className="d-flex mt-4 mt-lg-0">
                            <Address />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
