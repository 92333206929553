import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavbarMenu from '../../../Components/NavbarMenu';
import ImageContent from '../../../Components/ImageContent';
import PK from '../../../assets/img/image1-02.jpg';
import Reinventing from '../../../assets/img/reinventing-02.jpg';
import Smart from '../../../assets/img/smart-02.jpg';
import Needs from '../../../assets/img/how-it-serves-your-needs-02.jpg';
import appmock from '../../../assets/img/mockup4.png';
import vnrmock from '../../../assets/img/mockup42.png';
import messageaway from '../../../assets/img/message-away.png';
import allaccounts from '../../../assets/img/all-accounts.png';
import protection from '../../../assets/img/Protection.png';
import data from '../../../assets/img/data.png';
import Apple from '../../../assets/img/apple.png';
import PlayStore from '../../../assets/img/play-store.png';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Footer from '../../../Components/Footer';
import { useTranslation } from 'react-i18next';

export default function Home() {

    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className={`p-0 bannerE${curLang === 'ar' ? 'Ar' : 'n'}`}>
                    <div className="hero home-hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`apache ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase`}>{t('homepage.title-t1')} <br /> {t('homepage.title-t2')}</h1>
                                <p className={`white fs-30 ${curLang === 'ar' ? 'almarai' : 'montserrat'} mb-0 font-weight-bold text-capitalize`}>{t('homepage.title-t-p')}</p>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>

            <section className="pioneering-kingdom py-5">
                <ImageContent
                    title={t('homepage.title-p')}
                    content={
                        <>
                            <p className="content">{t('homepage.content-p')}</p>
                        </>
                    }
                    fullcontent={
                        <>
                            {curLang === 'ar' ?
                                <>
                                    <p className="content">  أوراق هي مِنصّة تداول افتراضية رائدة، تهدف إلى تمكين شبكة الوسطاء والتجار المعتمدين، ضمن سوق الأسهم الوطني؛ من تداول وبيع الأسهُم العامة، التي يتم التفاوض عليها خارج السوق  بشكل خاص . يوفر نظامنا المرن للتجارة الافتراضيّة، للمستثمرين من الأفراد والشركات؛ الانخراط الآمن في إدارة الصفقات والتداولات الخاصّة. إننا نسعى من خلال سوقنا التجاري، الذكي والآمن تقنياً، إلى تسهيل انضمام المساهمين إلى سوق المضاربات الإلكتروني، والتفاوض، وإدارة التداولات. يتمتع الوسطاء والمستثمرون في منصة أوراق بخاصية الوصول الحصري للصفقات الخاصة، مما يجعلهم مطلعين على آخر مستجدات السوق من عروض وطلبات. . تعزز أدواتنا التفاوضية المتكاملة على اتخاذَ قرارات تداولية عالية الكفاءة، وخلقَ شبكات تجارية تداولية على مستوى البلاد بكل سهولة.</p>
                                    <p className="smb-content">حلولنا للتجارة التداولية الإلكترونية لا تُضاهى - كل ذلك في مكان واحد!</p>
                                </>
                                :
                                <>
                                    <p className="content">AWRAAQ is a leading virtual block trade platform, aimed to empower the nationwide dynamic network of registered stock brokers and block traders in privately negotiated public equities. Our virtual block trade ecosystem enables individual investors and corporate entities to securely engage in electronic block trade management. Through our technically smart and secure block trade marketplace, we facilitate and engage market participants in electronic bidding, negotiation and trade management. Brokers and investors have exclusive access to large trade volumes, giving them an opportunity to actively engage in portfolio diversification. Our fully integrated, off-market trade negotiation tools enables stock market participants to make secure self-directed block trade decisions and establish nationwide trading networks, seamlessly.</p>
                                    <p className="smb-content">Delivering unrivalled electronic block trade solutions- all in one place.</p>
                                </>
                            }
                        </>
                    }
                    image={PK}
                />
            </section>

            <section className="pioneering-kingdom bg-teamE2 py-5">
                <ImageContent
                    title={t('homepage.title-p1')}
                    content={
                        <>
                            <p className="content">{t('homepage.content-p1')}</p>
                        </>
                    }
                    fullcontent={
                        <>
                            {curLang === 'ar' ?
                                <>
                                    <p className="content">تُمثل شبكتنا محوراً لعمليات تداول الصفقات الخاصة بشكل رقمي، وهو ما يجعلها منصة فريدة من نوعها وغير تقليدية. تعمل أوراق على إزالة العقبات أمام سير العمل التقليدي في تجارة تداول الصفقات الخاصة، وذلك بتمكين المتداولين في سوق الأسهم من الانخراط في عمليات المزايدة والتفاوض الإلكترونية. وهذه العمليات يمكن إجراؤها من خلال شبكة مركزية من المتداولين خارج  السوق. يتم التداول والتفاوض على الصفقات الخاصة بسرعة وأمان  عبر نظامنا الرقمي المتكامل. لقد أحدثت التقنيات المتطورة، المدعومة بشبكات من الوسطاء المعتمدين؛ تحولاً في عمليات التفاوض وإدارة تداول الصفقات الخاصة التقليدية. </p>
                                    <p className="smb-content">نضمن لك تداول آمن وسريع وسلس!</p>
                                </>
                                :
                                <>
                                    <p className="content">Our network is a hub of digitalized block trade processes, untapped and unrivalled. AWRAAQ de-complicates the traditional block trade workflow and engages market participants in electronic bidding and negotiation processes, which can be transacted via authorized brokers, effortlessly. Our inventive, user friendly system is engineered around efficient and secure trade and negotiation procedures. Through a centralized network of market participants, trading, negotiation and management of block trade deals are done swiftly via our vertically integrated digitized system. Our cutting-edge technology, augmented with registered networking of brokers, has transformed the traditional industry practices of negotiation process and block trade management.</p>
                                    <p className="smb-content">Safe, Fast, Seamless trading guaranteed!</p>
                                </>
                            }
                        </>
                    }
                    image={Reinventing}
                    orderleft="order-1 order-md-2"
                    orderright="order-2 order-md-1"
                />
            </section>

            <section className="pioneering-kingdom py-5">
                <ImageContent
                    title={t('homepage.title-p2')}
                    content={
                        <>
                            <p className="content">{t('homepage.content-p2')}</p>
                        </>
                    }
                    fullcontent={
                        <>
                            {curLang === 'ar' ?
                                <>
                                    <p className="content">استمدت أوراق وجودها من تكنولوجيا  رقمنة شبكات تداول الصفقات الخاصة، حيث استبدلت الأنماط التقليدية للتداول والتفاوض خارج السوق، وذلك من خلال انشاء ب مجتمعاً افتراضياً يجمع  المستثمرين والتجار والوسطاء؛ ليتمكنوا من التفاوض وإتمام الصفقات الخاصة بسهولة وأمان  من خلال منصة أوراق  الرقمية. قمنا بإستبدال الطرق التقليدية  للتبادل التجاري بالخوارزميات والأدوات الرقمية، التي تمكن المستثمرين من إجراء الصفقات التجارية بضغطة زر واحدة. لقد وظّفنا التكنولوجيا  في عمليات التداول لأتْمَتَة الصفقات التجارية  على أساس علمي، حتى يتمكن عملائنا من عيش تجربة التفاوض وإدارة الصفقات التجارية بأنفسهم.  </p>
                                    <p className="smb-content">شركة أوراق رائدة في صناعة التكنولوجيا  لتقديم تجربة لا مثيل لها</p>
                                </> :
                                <>
                                    <p className="content">We have leveraged technology to digitize and automate block trade networks. Replacing the traditional methods of off-market block trade, we have created a virtual community to accommodate investors, traders and brokers who can negotiate and close deals virtually. Our dashboard and tools have replaced the old ways of block trade exchange with a single click. We’ve integrated technology into processes to automate block trade deals and have built an infrastructure which will enable our clients to experience centralized bidding, negotiation and block trade management.</p>
                                    <p className="smb-content">Pioneer in industry shaping technology to deliver an experience which is unrivalled!</p>
                                </>
                            }
                        </>
                    }
                    image={Smart}
                />
            </section>

            <section className="pioneering-kingdom py-5  bg-teamE2">
                <ImageContent
                    title={t('homepage.title-p3')}
                    content={
                        <>
                            <p className="content">{t('homepage.content-p3')}</p>
                        </>
                    }
                    fullcontent={
                        <>
                            {curLang === 'ar' ?
                                <>
                                    <p className="content">إن التزامنا الكامل برؤيتنا لأتمتة الصفقات والتداولات التجارية خارج سوق الأسهم؛ جعلنا من رواد الصناعة التكنولوجية في هذا الميدان. لقد عدلت أوراق قواعد اللعبة في رقمنة المفاوضات التجارية، واستبدلت العمليات الشاقة والمستنزفة للوقت بأخرى آمنة وفعالة تتم بضغطة زر واحد. لقد تصورنا شكل المستقبل ورصدنا التحول نحو رقمنة الصفقات التجارية. مع أوراق، لن تفوتَك صفقة بعد الآن! </p>
                                    <p className="smb-content">المستقبل هاهنا! </p>
                                </>
                                :
                                <>
                                    <p className="content">Strong and continuous adherence to our vision of block trade process automation has made us the industry pioneer. Our success story is a game changer in digitizing block trade negotiation and has replaced laborious, time consuming processes with a single click. We have imagined the future and have pushed the boundaries on digitalization for block trade transactions. Not a chance that any block trade deal will now miss your bid or stay unsettled for longer than you take to snap a finger.</p>
                                    <p className="smb-content">Future is here - it's yours!</p>
                                </>
                            }
                        </>
                    }
                    image={Needs}
                    orderleft="order-1 order-md-2"
                    orderright="order-2 order-md-1"
                />
            </section>
            <section className="unrivalled-brokerage py-5 py-sm-0">
                <Container>
                    <Row>
                        <Col lg={8} className="d-flex align-items-center content-wrapper">
                            <div>
                                <h2 className={`${curLang === 'ar' ? 'almarai' : 'montserrat'} white fs-38 fw-semi-bold mb-4`}>{t('homepage.title-h2')}</h2>
                                <p className="white fw-medium fs-22 lh-35">{t('homepage.title-h2-p')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/*  Mobile application ----------------- */}
            <section className="mobile-application py-5 bg-teamE2">
                <Container>
                    <Row>
                        <Col md={5} lg={6} className="order-2 order-md-1 d-flex align-items-center">
                            <img src={appmock} alt="mobilemockup" className="img-fluid" />
                        </Col>
                        <Col md={7} lg={6} className="order-1 order-md-2 d-flex align-items-center">
                            <div>
                                <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                    {t('homepage.app-title-h2')}
                                </h2>
                                <p className=" fs-18 sub-title-black">{t('homepage.app-title-h2-p')}</p>
                                <p className=" fs-18 fw-semi-bold">{t('homepage.app-title-h2-p2')}</p>
                                <div className={`mt-4 text-center text-md-${curLang === 'ar' ? 'right' : 'left'}`}>
                                    <Button variant="contained" color="primary" component={Link} to={'/'} className="green-btn mr-0 mr-sm-4 mb-4 mb-md-0 d-inline-block">
                                        <img src={Apple} alt="Apple" className="img-fluid mr-2" /> {t('homepage.apple-store')}
                                    </Button>
                                    <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.awraaq" className="green-btn mb-4 mb-md-0 d-inline-block">
                                        <img src={PlayStore} alt="Google Play" className="img-fluid mr-2" /> {t('homepage.google-play')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/** Awraaq Digital Protection Guarantee */}
            <section className="mobile-application py-5 ">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {t('homepage.garantee-title-h2')}
                            </h2>
                        </Col>

                        <Col md={6} lg={6} className="order-1 order-md-1 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img class="css-10cvse8" role="presentation" src={data}></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">

                                    <p className=" fs-18 sub-title-black">{t('homepage.garantee-title-h2-p')}</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6} lg={6} className="order-1 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={protection} class="css-10cvse8" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">{t('homepage.garantee2-title-h2-p')}</div>
                            </div>
                        </Col>

                        <Col md={6} lg={6} className="order-1 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={allaccounts} class="css-10cvse8" role="presentation"></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">{t('homepage.garantee3-title-h2-p')}</div>
                            </div>
                        </Col>

                        <Col md={6} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={messageaway} class="css-10cvse8" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">{t('homepage.garantee4-title-h2-p')}</div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            {/*  Virtual Rooms ----------------- */}
            <section className="mobile-application py-52 bg2">
                <Container>
                    <Row>

                        <Col md={7} lg={6} className="order-1 order-md-1 d-flex align-items-center">
                            <div>
                                <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                    {t('homepage.vnr-title-h2')}
                                </h2>
                                <p className=" fs-18 sub-title-black ">{t('homepage.vnr-title-h3-p')}</p>
                                <p className=" fs-18 sub-title-black font-weight-bold">{t('homepage.vnr-title-h4-p')}</p>
                                <p className=" fs-18 sub-title-black">{t('homepage.vnr-title-h5-p')}</p>

                                <div className={`mt-4 text-center text-md-${curLang === 'ar' ? 'right' : 'left'}`}>
                                    <Button variant="contained" color="primary" component={Link} to={'/'} className="green-btn mr-0 mr-sm-4 mb-4 mb-md-0 d-inline-block">
                                        <img src={Apple} alt="Apple" className="img-fluid mr-2" /> {t('homepage.apple-store')}
                                    </Button>
                                    <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.awraaq" className="green-btn mb-4 mb-md-0 d-inline-block">
                                        <img src={PlayStore} alt="Google Play" className="img-fluid mr-2" /> {t('homepage.google-play')}
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col md={5} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <img src={vnrmock} alt="mobilemockup" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}