import { lazy } from 'react';

const ClientLogin = lazy(() => import  ('../Views/Clients/Login/Login'));
const LoginOtp = lazy(() => import('../Views/Clients/Login/LoginOtp'));
const Register = lazy(() => import  ('../Views/Clients/Register/Register'));
const NewClientLogin = lazy(() => import  ('../Views/Clients/Login/NewClientLogin'));
const SetPassword = lazy(() => import  ('../Views/Clients/Login/SetPassword'));
const ForgetPassword = lazy(() => import  ('../Views/Institutional/ForgetPassword/ForgetPassword'));
const SetForgetPassword = lazy(() => import  ('../Views/Institutional/SetPassword/SetPassword'));

const client_guest = [
    { path: '/login', exact: true, name: "ClientLogin", component: ClientLogin },
    { path: '/otp-verification', exact: true, name: "Otp Verification", component: LoginOtp },
    { path: '/new-client-login', exact: true, name: "New Client Login", component: NewClientLogin },
    { path: '/set-password', exact: true, name: "Set Password", component: SetPassword },
    { path: '/register', exact: true, name: "Register", component: Register },
    { path: '/forget-password', exact: true, name: "Forget Password", component: ForgetPassword },
    { path: '/set-forget-password', exact: true, name: "Set Password", component: SetForgetPassword }
];

export default client_guest;