import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';

const accordiandata = [
    { id: '8001', question: 'I am a client of a brokerage firm, how can I register?', answer: 'AWRAAQ’s application is  available to download on Apple and Google Playstore.' },
    { id: '8002', question: 'What functionality is available in the Application?', answer: 'Institutional and individual members can submit an Indication of Interest (IoI) and view all orders and offers made on the submitted block trade. He/she can monitor the negotiation process and instruct his/her broker to either accept or reject the offer. Institutional and Individual investors can receive Request for Quote (RFQ) to bid or ask on the submitted orders through the App.' },
    { id: '8003', question: 'Who is the institutional trader?', answer: 'Institutional traders are family offices, fund managers, private accounts and corporations.' },
    { id: '8004', question: 'Who is the individual investor?', answer: 'Individual investors are professional  investors who qualify to invest in the stock market. Applicants should be 18 years of age and above.' },
    { id: '8006', question: 'Why is my National/Iqama ID required?', answer: 'National ID and Iqama numbers are mandatory for issuance of a unique client identification number and for linking your account with that of your broker. Once registration has been done, all offers/orders and reports will be generated against the customer ID to complete the transactions.' },
    { id: '8007', question: 'Where is my personal information maintained?', answer: 'Your identity is maintained under your broker’s terms and conditions. AWRAAQ will have no access to your personal identity information.' },
]

const accordiandataar = [
    { id: '8001', question: 'انا عميل لدى شركة وساطة، كيف يمكنني التسجيل؟', answer: 'تطبيق أوراق متاح للتحميل على آبل و جوجل بلاي ستور.' },
    { id: '8003', question: ' ما هي الوظائف المتوفرة في التطبيق؟', answer: 'يمكن للأعضاء المؤسسين والأفراد إرسال مؤشر الاهتمام (IoI) وعرض جميع الطلبات والعروض المقدمة في الصفقات الخاصة. من الممكن أيضًا متابعة  عملية التفاوض وإصدار تعليمات للوسيط الخاص به إما بقبول العرض أو رفضه. يمكن للمستثمرين من المؤسسات والأفراد تلقي طلب عرض أسعار (RFQ) لتقديم عطاءات أو طلب على الطلبات المقدمة من خلال التطبيق.' },
    { id: '8004', question: 'من هو المتداول المؤسسي؟', answer: 'المتداولون المؤسسون هم مكاتب عائلية ومديرو صناديق وحسابات خاصة وشركات.' },
    { id: '8005', question: ' من هو المستثمر الفردي؟', answer: 'المستثمرون الأفراد هم مستثمرون محترفون مؤهلون للاستثمار في سوق الأسهم المالية. يجب أن يكون عمر المتقدم 18 عامًا فما فوق.' },
    { id: '8006', question: ' لماذا يلزم إدخال بيانات بطاقة الهوية الوطنية / الإقامة الخاصة بي؟', answer: 'رقام الهوية الوطنية والإقامة إلزامية لإصدار رقم تعريف شخصي وربط حسابك بحساب الوسيط الخاص بك. بمجرد الانتهاء من التسجيل سيتم إصدار التقارير  لجميع العروض والطلبات الخاصة بك لاستكمال عملية البيع أو الشراء.' },
    { id: '8007', question: 'أين يتم حفظ معلوماتي الشخصية؟', answer: 'يتم الاحتفاظ بهويتك بموجب أحكام وشروط الوسيط الخاص بك. لن يكون لدى أوراق إمكانية الوصول إلى معلومات هويتك الشخصية.' },
]

export default function MembersFaq() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const accordianArr = curLang === 'ar' ? accordiandataar: accordiandata;

    return (
        <div className="accordian-faq">
            {accordianArr.map( (data) => 
                <Accordion expanded={expanded === `panel_${data.id}`} onChange={handleChange(`panel_${data.id}`)} key={data.id}>
                    <AccordionSummary
                        expandIcon={expanded === `panel_${data.id}` ? <RemoveIcon /> : <AddIcon />}
                        aria-controls={`panel_${data.id}bh-content`}
                        id={`panel_${data.id}bh-header`}
                    >
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-20 faq-ar">{t('faqpage.q')}</p>  
                            <p className="mb-0 text-black fw-medium fs-20">{data.question}</p>  
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-18 outer-space faq-ar"> {t('faqpage.a')}</p>  
                            <p className="mb-0 text-black fw-medium fs-18 outer-space">{data.answer}</p> 
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}
