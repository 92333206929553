import React from 'react';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DraftsIcon from '@material-ui/icons/Drafts';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import ClockIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next';

export default function Address() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <>
            <div className="contact-details-wrapper w-100">
                <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-5`}>
                    {t('contactuspage.add-h2')}
                </h2>
                <ul className="list-unstyled contact-ul">
                    <li>
                        <div className="d-flex">
                            <LocationOnIcon className="green" />
                            <div>
                                <p className="mb-0 fs-16 mine-shaft fw-medium ml-2 lh-24">{t('contactuspage.add-p1')}</p>
                                <p className="mb-0 fs-16 mine-shaft fw-medium ml-2 lh-24">{t('contactuspage.add-p2')}</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex">
                            <ClockIcon className="green" />
                            <div>
                                <p className="mb-0 fs-16 mine-shaft fw-medium ml-2 lh-24" style={{ marginRight: 8 }}>{t('contactuspage.workinghours-p1')}</p>
                                <p className="mb-0 fs-16 mine-shaft fw-medium ml-2 lh-24" style={{ marginRight: 8 }}>9AM - 5PM</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex align-items-center">
                            <PhoneIcon className="green" />
                            <a href="tel:+966112630401" className="fs-16 mine-shaft fw-medium ml-2 lh-30" style={{ marginRight: 8 }}>0112630401</a>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex align-items-center">
                            <DraftsIcon className="green" />
                            <a href="mailto:info@awraaq.com" className="fs-16 mine-shaft fw-medium ml-2 lh-30" style={{ marginRight: 8 }}>info@awraaq.com</a>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex align-items-center">
                            <LanguageIcon className="green" />
                            <Link to="https://www.awraaq.com" target="_blank" className="fs-16 mine-shaft fw-medium ml-2 lh-30" style={{ marginRight: 8 }}>www.awraaq.com</Link>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
