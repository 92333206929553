import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";
import { onMessage, getToken } from 'firebase/messaging';
import "regenerator-runtime/runtime";

const firebaseConfig = {
    apiKey: "AIzaSyA0ZJNDSh7VQsXne6WoXhr0EyLx2sNcjBM",
    authDomain: "awraaq-53671.firebaseapp.com",
    projectId: "awraaq-53671",
    storageBucket: "awraaq-53671.appspot.com",
    messagingSenderId: "536310470373",
    appId: "1:536310470373:web:363aa86e97b332ee2fe60c",
    measurementId: "G-L4K67QWCCH"
};

let app;
let message;

function fireabseInit() {
    app = initializeApp(firebaseConfig);
    message = getMessaging(app)
}

const getBrowserToken = async () => {
    try {
        const token = await getToken(message, { vapidKey: "BM7eEH8jjE_nwDgS6sX2omlJctC07lL01LCuJFyerwn63eH6W9zkgskWFZIUG5q3-NVNSFRcJwRulzbHFa-3x0c" });
        return token;
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
    }
}
// we are registering an observer which detects new push messages
// and passes the message to the callback nextFunction 
const onMessageListener = (nextFunction) => {
    return onMessage(message, nextFunction);
}

export { fireabseInit, getBrowserToken, onMessageListener }
