import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function ImageContent(props) {

    const [ showFull, setShowFull ] = useState(false);
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <>
            <Container>
                <Row>
                { showFull === false &&
                    <>
                        <Col md={7} lg={8} className={props.orderleft}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {props.title}
                            </h2>
                            {props.content}
                            <Button variant="contained" color="primary" className={`green-btn${curLang}`}
                                onClick={() => setShowFull(!showFull)}
                            >
                                {t('homepage.read-more')}
                            </Button>
                        </Col>
                        <Col md={5} lg={4} className={props.orderright}>
                            <img src={props.image} alt={props.title} className="img-fluid shadow-image mt-5 mt-md-0" />
                        </Col>
                    </>
                }
                    { showFull === true &&
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {props.title}
                            </h2>
                            {props.fullcontent}
                            <Button variant="contained" color="primary" className={`green-btn${curLang}`}
                                onClick={() => setShowFull(!showFull)}
                            >
                                {t('homepage.read-less')}
                            </Button>
                        </Col>
                    }
                </Row>
            </Container>    
        </>
    )
}
