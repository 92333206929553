import React from 'react';
import { Form } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function LoginForm(props) {
    
    const { handleSubmit, handleChange, values, errors } = props;

    return (
        <>
            <Form onSubmit={handleSubmit} noValidate>
                <Form.Group>
                    <TextField
                        id="username"
                        label="Username"
                        variant="outlined"
                        size="small"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                    />
                    {errors.email && <small className="text-danger">{errors.email}</small>}

                </Form.Group>
                <Form.Group>
                    <TextField
                        id="username"
                        label="Password"
                        variant="outlined"
                        size="small"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                    />
                    {errors.password && <small className="text-danger">{errors.password}</small>}

                </Form.Group>
                <div className="d-flex align-items-center justify-content-between">
                    <Link to="/new-client-login" className="fs-14 brown"><ins>New Broker</ins></Link> 
                    <Button type="submit" variant="contained" color="primary" className="orange-btn-mui px-5">
                        Log In
                    </Button>
                </div>
                <div>
                <Link to='/forget-password' className="fs-14 brown"><ins>Forgot Password</ins></Link>
                </div>
            </Form>
        </>
    )
}
