import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';

const accordiandata = [
    { id: '7001', question: 'I have a deal in the negotiation room, what to do?', answer: 'You can start the negotiation process by submitting or matching the corresponding offers. You need to make sure that the specified price is within the market guidelines.' },
    { id: '7002', question: 'Who can see the deals in the negotiation room?', answer: 'Access to details of bid and asking price is limited only to the concerned parties involved in the negotiation. Awraaq’s platform ensures confidentiality of the deal insights and is in compliance with the Tadawul by-laws for misconduct penalty.' },
    { id: '7003', question: 'What does Match mean?', answer: 'Match means that the participant would like to match the corresponding price and volume offers.' },
    { id: '7004', question: 'When can I accept and reject an offer?', answer: 'An accept radio button will appear when selecting the final offer check box. The deal, however, can be rejected at any time..' },
    { id: '7005', question: 'What does “Final Selection” mean?', answer: 'Final selection means that the quotes for the offer are final.' },
    { id: '7006', question: 'Which deal can qualify for the negotiation process?', answer: 'Deals submitted by institutional traders only qualify for the negotiation process.' },
]

const accordiandataar = [
    { id: '7001', question: 'لدي اتفاق في غرفة المفاوضات ، ماذا أفعل؟    ', answer: 'يمكنك بدء عملية التفاوض من خلال تقديم أو مطابقة العروض المقابلة. تحتاج إلى التأكد من أن السعر المحدد ضمن إرشادات السوق.    ' },
    { id: '7002', question: 'من يمكنه رؤية الصفقات في غرفة المفاوضات؟', answer: 'يقتصر الوصول إلى تفاصيل سعر العرض وسعر الطلب فقط على الأطراف المعنية المشاركة في التفاوض. تضمن منصة أوراق السرية التامة في ما يتعلق بالصفقة وتتوافق مع قوانين تداول بشأن عقوبة سوء السلوك.' },
    { id: '7003', question: 'ماذا يعني ”تطابق“؟    ', answer: 'عني أن المشارك يرغب في مطابقة السعر المقابل والحجم.' },
    { id: '7004', question: 'متى يمكنني قبول العرض أو رفضه؟    ', answer: 'سوف يظهر زر اختيار القبول عند اختيار مربع  العرض النهائي. ومع ذلك ، يمكن رفض الصفقة في أي وقت.' },
    { id: '7005', question: ' ماذا يعني "الاختيار النهائي"؟', answer: 'يعني الاختيار النهائي لعروض الأسعار. ' },
    { id: '7006', question: 'ما هي الصفقة التي يمكن أن تكون مؤهلة لعملية التفاوض؟', answer: 'الصفقات المقدمة من قبل المتداولين المؤسسين مؤهلة فقط لعملية التفاوض.' },
]

export default function NegotiationRoomFaq() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const accordianArr = curLang === 'ar' ? accordiandataar : accordiandata;

    return (
        <div className="accordian-faq">
            {accordianArr.map((data) =>
                <Accordion expanded={expanded === `panel_${data.id}`} onChange={handleChange(`panel_${data.id}`)} key={data.id}>
                    <AccordionSummary
                        expandIcon={expanded === `panel_${data.id}` ? <RemoveIcon /> : <AddIcon />}
                        aria-controls={`panel_${data.id}bh-content`}
                        id={`panel_${data.id}bh-header`}
                    >
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-20 faq-ar"  >{t('faqpage.q')}</p>
                            <p className="mb-0 text-black fw-medium fs-20">{data.question}</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-18 outer-space faq-ar" > {t('faqpage.a')}</p>
                            <p className="mb-0 text-black fw-medium fs-18 outer-space">{data.answer}</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}
