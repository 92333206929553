import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavbarMenu from '../../../Components/NavbarMenu';
import ElectronicQuotation from '../../../assets/img/01.svg';
import Negotiations from '../../../assets/img/02.svg';
import Block from '../../../assets/img/03.svg';
import Footer from '../../../Components/Footer';
import Product from './Product';
import { useTranslation } from 'react-i18next';
import one from '../../../assets/img/1.png';
import two from '../../../assets/img/2.png';
import three from '../../../assets/img/3.png';
import four from '../../../assets/img/4.png';
import five from '../../../assets/img/5.png';
import six from '../../../assets/img/6.png';
import seven from '../../../assets/img/7.png';
import Apple from '../../../assets/img/apple.png';
import PlayStore from '../../../assets/img/play-store.png';
import goldvolume from '../../../assets/img/gold-volume.png';
import goldvolumear from '../../../assets/img/gold-volume-ar.png';
import silvervolume from '../../../assets/img/silver-volume.png';
import silvervolumear from '../../../assets/img/silver-volume-ar.png';
import platvolume from '../../../assets/img/plat-volume.png';
import platvolumear from '../../../assets/img/plat-volume-ar.png';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { blue } from '@material-ui/core/colors';


export default function MarketPlace() {

    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className={`p-0 faq-bannerE${curLang === 'ar' ? 'Ar' : 'n'}`}>
                    <div className="hero contact-hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`green ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase`}>{t('maketplacepage.title-h1')}</h1>
                                <p className={`green fs-30 ${curLang === 'ar' ? 'almarai' : 'montserrat'} mb-0 font-weight-bold text-capitalize`}>{t('maketplacepage.title-h1-p')}</p>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>
            <section className="marketplace-description py-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {t('maketplacepage.title-h2')}
                            </h2>
                            <p className="mb-0 fw-medium text-black fs-16 lh-26">{t('maketplacepage.title-h2-p')}</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="product py-5 bg-teamE2">
                <Product
                    img={ElectronicQuotation}
                    title={t('maketplacepage.product-title-1')}
                    description={
                        <>
                            <p className="fs-16 text-black fw-medium lh-28 mb-0">{t('maketplacepage.product-p-1')}</p>
                        </>
                    }
                />
            </section>

            <section className="product py-5">
                <Product
                    img={Negotiations}
                    title={t('maketplacepage.product-title-2')}
                    subtitle={
                        <>
                            <p className="fs-28 fw-semi-bold mb-0">{t('maketplacepage.product-subtitle-2')}</p>
                        </>
                    }
                    description={
                        <>
                            <p className="fs-16 text-black fw-medium lh-28 mb-0">{t('maketplacepage.product-p-2')}</p>
                        </>
                    }
                    orderleft="order-1 order-xl-2"
                    orderright="order-2 order-xl-1"
                />
            </section>

            <section className="product py-5 bg-teamE2">
                <Product
                    img={Block}
                    title={t('maketplacepage.product-title-3')}
                    description={
                        <>
                            <p className="fs-16 text-black fw-medium lh-28 mb-0">{t('maketplacepage.product-p-3')}</p>
                        </>
                    }
                />
            </section>

            <section className="marketplace-description py-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>{t('maketplacepage.section-title-h2')}</h2>
                            <p className="mb-0 fw-medium text-black fs-16 lh-26">{t('maketplacepage.section-title-h2-p')}</p>
                        </Col>
                    </Row>


                </Container>
            </section>

            {/** This is the How to get started section */}

            <section className="mobile-application py-5 bg ">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {t('maketplacepage.how-title-h2')}
                            </h2>
                            <p className="mb-0 fw-medium light-black fs-16 lh-26">{t('maketplacepage.how-title-h2-p')}</p>

                        </Col>
                        {/**----------------1----------------- */}
                        <Col xs={12}>
                            <div class="css-2">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img class="css-10cvse82" role="presentation" src={one}></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr ">

                                    <p className=" stepfont light-black">{t('maketplacepage.getstarted-title-h2-p')}</p>
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted-title-h2-p2')}</p>

                                    <div className={`mt-4 text-center `}>
                                        <Button variant="contained" color="primary" component={Link} to={'/'} className="green-btn mr-0 mr-sm-4 mb-4 mb-md-0 d-inline-block">
                                            <img src={Apple} alt="Apple" className="img-fluid mr-2" /> {t('homepage.apple-store')}
                                        </Button>
                                        <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.awraaq" className="green-btn mb-4 mb-md-0 d-inline-block">
                                            <img src={PlayStore} alt="Google Play" className="img-fluid mr-2" /> {t('homepage.google-play')}
                                        </Button>
                                    </div>
                                    <p className=" divi stepfont light-black font-weight-bold ">{t('maketplacepage.getstarted-title-h2-p3')}</p>
                                </div>
                            </div>
                        </Col>
                        {/**----------------2----------------- */}
                        <Col md={7} lg={6} className="order-1 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={two} class="css-10cvse82" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted2-title-h2-p')} <a style={{ color: blue }} href="https://awraaq.com">www.awraaq.com</a> {t('maketplacepage.getstarted2.2-title-h2-p')}</p>
                                </div>
                            </div>
                        </Col>
                        {/**----------------3----------------- */}

                        <Col md={7} lg={6} className="order-1 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={three} class="css-10cvse82" role="presentation"></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted3-title-h2-p')}</p>

                                </div>
                            </div>
                        </Col>
                        {/**----------------4----------------- */}

                        <Col md={7} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={four} class="css-10cvse82" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted4-title-h2-p')}</p>
                                    <p className="trans ">{t('maketplacepage.getstarted4-title-h2-p2')}</p>
                                </div>
                            </div>
                        </Col>
                        {/**----------------5----------------- */}

                        <Col md={7} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={five} class="css-10cvse82" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted5-title-h2-p')}</p>
                                    <p className="trans ">{t('maketplacepage.getstarted4-title-h2-p2')}</p>


                                </div>
                            </div>

                        </Col>
                        {/**----------------6----------------- */}
                        <Col md={7} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={six} class="css-10cvse82" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted6-title-h2-p')}</p>
                                    <p className=" stepfont light-black font-weight-bold ">{t('maketplacepage.getstarted4-title-h2-p2')}</p>


                                </div>
                            </div>
                        </Col>
                        {/**----------------7----------------- */}

                        <Col md={7} lg={6} className="order-2 order-md-2 d-flex align-items-center">
                            <div class="css-17c6rks">
                                <div class="css-16yn8ku">
                                    <div class="css-1nuzlif">
                                        <img src={seven} class="css-10cvse82" role="presentation" ></img>
                                    </div>
                                </div>
                                <div class="css-1syxrhr">
                                    <p className=" stepfont light-black btn-esraa d-inline-block">{t('maketplacepage.getstarted7-title-h2-p')}</p>
                                    <p className="trans ">{t('maketplacepage.getstarted4-title-h2-p2')}</p>
                                </div>
                            </div>
                        </Col>
                        {/**----------------END----------------- */}
                    </Row>
                    <div class="divi">
                        <p className=" stepfont light-black font-weight-bold ">{t('maketplacepage.getstarted8-title-h2-p')}</p>
                    </div>
                </Container>
            </section>
            {/** This is the How to get started section EXAMPLE 2 ---------------------- */}

            {/** Get ARMED to Block Trade Now!*/}

            <section className={`pioneering-kingdom py-5  ${curLang === 'ar' && 'titlear'}`}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold`}>
                                {t('maketplacepage.armed-title-h2')}
                            </h2>
                            <p className="content font-weight-bold ">{t('maketplacepage.armed-title-h2-p2')}.</p>
                            <p className="content">{t('maketplacepage.armed-title-h2-p')}</p>
                            <p className="content">{t('maketplacepage.armed-title-h2-p3')} <a style={{ color: blue }} href="mailto:info@awraaq.com">info@awraaq.com</a></p>

                        </Col>
                    </Row>
                </Container>

            </section>
            {/* Pricing section --------------------------------*/}
            <section className="mobile-application py-52 bg3">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {t('maketplacepage.pricingbox-title-h2')}
                            </h2>
                            <p className="content font-weight-bold light-black ">{t('maketplacepage.pricingbox-title-h2-p')}.</p>
                        </Col>
                        {/* switching lang */}
                        <>
                            {curLang === 'ar' ?
                                <>
                                    <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img src={silvervolumear} class="prices-css" role="presentation" ></img>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4} lg={4} className="order-1 order-md-2 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img class="prices-css" role="presentation" src={goldvolumear}></img>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>

                                    <Col md={4} lg={4} className="order-1 order-md-2 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img src={platvolumear} class="prices-css" role="presentation"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                                :
                                <>
                                    <Col md={4} lg={4} className="order-1 order-md-1 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img src={silvervolume} class="prices-css" role="presentation" ></img>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4} lg={4} className="order-1 order-md-2 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img class="prices-css" role="presentation" src={goldvolume}></img>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>

                                    <Col md={4} lg={4} className="order-1 order-md-2 d-flex align-items-center">
                                        <div class="css-17c6rks">
                                            <div class="css-16yn8ku">
                                                <div class="css-1nuzlif">
                                                    <img src={platvolume} class="prices-css" role="presentation"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            }
                        </>
                        {/* End switching lang */}
                    </Row>
                    <p className="content font-weight-bold light-black smallersizefont">{t('maketplacepage.pricingbox-title-h2-p2')}.</p>
                    <p className="content font-weight-bold light-black smallersizefont">{t('maketplacepage.pricingbox-title-h2-p3')} <a style={{ color: blue }} href="mailto:business@awraaq.com">business@awraaq.com</a></p>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
