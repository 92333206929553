import { lazy } from 'react';
import ForgotPassword from '../Views/Admin/Login/ForgotPassword';

const AdminOtpVerification = lazy(() => import('../Views/Admin/Login/otpVerification'));
const Login = lazy(() => import('../Views/Admin/Login/Login'));

const admin_guest = [
    { path: '/admin', exact: true, name: "Login", component: Login },
    { path: '/admin/forgot-password', exact: true, name: "Forgot password", component: ForgotPassword },
    { path: '/admin/otp-verification', exact: true, name: "Otp Verification", component: AdminOtpVerification },
];

export default admin_guest;