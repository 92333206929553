export const apiurl = {
    client : {
        active_individual_offers : `/client/active-individual-offers/`,
        working_individual_deals : `/client/working-individual-deals/`,
        comapnyDs : `/client/companies-ds`,
        login : `/client/login`,
        loginOtp : `/client/login-otp-verify`,
        new_client_verify : `/client/new-client-verify`,
        set_password : `/client/set-password`,
        registration : `/client/registration`,
        countryDs: `/client/country-ds`,
        brokeragefirmDs : `/client/brokeragefirm-ds`,
        packageDs : `/client/package-ds`,
        individual_top_five_market_deals : `/client/individual-top-five-market-deals`,
        securitytype_ds  : `/client/securitytype-ds`,
        registerDevice  : `/client/register-device`,
    }
};