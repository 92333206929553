import { lazy } from "react";

const InstitutionalDashboard = lazy(() =>
  import("../Views/Institutional/Dashboard/Dashboard")
);
const PendingDealsOrderActionA = lazy(() =>
  //   import(
  //     "../Views/Institutional/InstitutionalDeals/PendingDeals/PendingDealsOrderActions"
  //   )
  import(
    "../Views/Admin/Deals/InstitutionalDeals/PendingDeals/PendingDealsOrderAction"
  )
);
const PendingDealsOrderAction = lazy(() =>
  import(
    "../Views/Institutional/InstitutionalDeals/PendingDeals/PendingDealsOrderActions"
  )
);
const MarketDeal = lazy(() =>
  import("../Views/Institutional/MarketDeal/MarketDeal")
);
const RequestNewDeal = lazy(() =>
  import("../Views/Institutional/MarketDeal/RequestNewDeal/RequestNewDeal")
);
const RequestNewOffer = lazy(() =>
  import("../Views/Institutional/MarketDeal/RequestNewOffer")
);
const InstitutionalNegotiationRoom = lazy(() =>
  import("../Views/Institutional/MarketDeal/Negotiation/NegotiationRoom")
);
const InstitutionalTransaction = lazy(() =>
  import("../Views/Institutional/Transaction/Transaction")
);
const PendingTransactionAction = lazy(() =>
  import(
    "../Views/Institutional/Transaction/PendingTransactionAction/PendingTransactionAction"
  )
);
const MyDeal = lazy(() => import("../Views/Institutional/MyDeal/MyDeal"));
const AccountsAndCard = lazy(() =>
  import("../Views/Institutional/AccountsAndCard/AccountsAndCard")
);
const ChangePassword = lazy(() =>
  import("../Views/Institutional/ChangePassword/ChangePassword")
);
const ExpiredOrders = lazy(() =>
  import("../Views/Institutional/MyDeal/ExpiredOrders")
);
// const PendingDealsOrderAction = lazy(() => import('../Views/Admin/Deals/InstitutionalDeals/PendingDeals/PendingDealsOrderAction'));

const UserManagementMaster = lazy(() =>
  import("../Views/Institutional/Clients/UserManagement")
);
const UserManagement = lazy(() =>
  import("../Views/Institutional/Clients/MobileClient/Client")
);
const MemberManagement = lazy(() =>
  import("../Views/Institutional/MemberManagement/MemberManagement")
);
const IndicationOfInterest = lazy(() =>
  import("../Views/IOI/IndicationOfInterest")
);
const SearchIndicationOfInterest = lazy(() =>
  import("../Views/IOI/Search/Search")
);
const PlaceOrder = lazy(() =>
  import("../Views/IOI/PlaceOrder/RequestNewIndication")
);
const RFQ = lazy(() => import("../Views/RFQ/RFQ"));
const InProcessRFQAction = lazy(() =>
  import("../Views/RFQ/InProcess/InProcessRFQAction")
);
const RequestOffer = lazy(() =>
  import("../Views/RFQ/PlaceOffer/RequestNewOffer")
);
const ContactUs = lazy(() => import("../Views/Website/ContactUs/ContactUs"));

const Report = lazy(() => import("../Views/Report/Report"));

const institutional = [
  {
    path: "/institutional-dashboard/deals-and-market",
    exact: true,
    name: "Institutional Dashboard",
    component: InstitutionalDashboard,
  },

  //   {
  //     path: "/pending-deals-order-actions",
  //     exact: true,
  //     name: "Institutional Dashboard",
  //     component: PendingDealsOrderAction,
  //   },

  {
    path: "/pending-deals-order-action",
    exact: true,
    name: "Institutional Dashboard",
    component: PendingDealsOrderAction,
  },
  {
    path: "/market-deal/deals",
    exact: true,
    name: "Market Deal",
    component: MarketDeal,
  },
  {
    path: "/request-new-deal",
    exact: true,
    name: "Request New Deal",
    component: RequestNewDeal,
  },
  {
    path: "/request-new-offer/m-tab",
    exact: true,
    name: "Request New Offer",
    component: RequestNewOffer,
  },
  {
    path: "/institutional-negotiation-room/m-tab",
    exact: true,
    name: "Institutional Negotiation Room",
    component: InstitutionalNegotiationRoom,
  },
  {
    path: "/institutional-transaction/tab",
    exact: true,
    name: "Institutional Transaction",
    component: InstitutionalTransaction,
  },
  {
    path: "/pending-institutional-transaction-action/:id",
    exact: true,
    name: "Institutional Transaction",
    component: PendingTransactionAction,
  },

  {
    path: "/my-deal/deals-tab",
    exact: true,
    name: "My Deal",
    component: MyDeal,
  },
  {
    // path: "/accounts-and-card",
    path: "/accounts",
    exact: true,
    name: "Accounts And Card",
    component: AccountsAndCard,
  },
  {
    path: "/change-password",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/expired-institutional-orders/:id",
    exact: true,
    name: "Expired Orders ",
    component: ExpiredOrders,
  },

  {
    path: "/user-management-masterclient/:tabkey?",
    exact: true,
    name: "User Management Master",
    component: UserManagementMaster,
  },
  {
    path: "/user-management",
    exact: true,
    name: "User Management",
    component: UserManagement,
  },
  {
    path: "/member-management-masterclient",
    exact: true,
    name: "Member Management",
    component: MemberManagement,
  },
  {
    // path: `/indication-of-interest/:id`,
    path: `/indication-of-interest/ioi-tab`,
    exact: true,
    name: "Indication Of Interest",
    component: IndicationOfInterest,
  },
  {
    path: "/search-order/:id",
    exact: true,
    name: "Search Indication Of Interest",
    component: SearchIndicationOfInterest,
  },
  {
    path: "/place-order/:id",
    exact: true,
    name: "Place Order",
    component: PlaceOrder,
  },
  {
    path: "/make-order/:id",
    exact: true,
    name: "Place Order",
    component: PlaceOrder,
  },
  {
    path: "/request-for-quote/rfq-tab",
    exact: true,
    name: "RFQ",
    component: RFQ,
  },
  {
    path: "/inprocess-rfq-action/",
    exact: true,
    name: "RFQ",
    component: InProcessRFQAction,
  },

  {
    path: "/request-offer/:id/:indId",
    exact: true,
    name: "Place Offer",
    component: RequestOffer,
  },
  {
    path: "/contactus",
    exact: true,
    name: "Contact Us",
    component: ContactUs,
  },
  { path: "/order-report", exact: true, name: "Report", component: Report },
];

export default institutional;
