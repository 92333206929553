import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
// import { Link } from 'react-router-dom'

import useForm from '../../../useForm';
import validate from '../../../validate/validateEmail';
import { getEncryptId } from "../../../utils/secure";

import { GuestApi } from '../../../utils/api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

export default function ForgotPassForm() {
    // const { setUser } = useContext(UserContext);
    let history = useHistory();

    const submit = async () => {
        try {
            const response = await GuestApi.post(`/admin/forgot-password`, values);
            const { status, data } = response;
            if (status === 200) {
                const encEmail = getEncryptId(values.email);
                history.push(`/admin/change-password/${encEmail}`);
            }
        } catch (e) {
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            } else {
                toast.error(`😱 Axios request failed: ${e}`);
            }
        }
    }

    const formObj = { email: "" };
    const { handleChange, handleSubmit, values, errors, setErrors } = useForm(
        submit,
        validate,
        formObj
    );

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Group>
                    <TextField 
                        id="RegistredEmailId" 
                        type="email"
                        label="Enter registred email id" 
                        variant="outlined" 
                        size="small"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                    />    
                    {errors.email && <small className="text-warning">{errors.email}</small>}

                </Form.Group>
                
                <div className="d-flex align-items-center justify-content-between">
                    <Button type="submit" variant="contained" color="primary" className="orange-btn-mui px-5">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    )
}


 

