import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function ContentCard(props) {

    const { children } = props;

    return (
        <>
            <section className="content-card py-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body className="p-4">
                                    { children }    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>    
        </>
    )
}
