import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Product(props) {
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <>
            <Container>
                <Row>
                    <Col xl={8} className={'d-flex mb-5 mb-xl-0 ' + props.orderleft}>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={props.img} alt={props.title} className="img-fluid" />    
                        </div>
                    </Col>
                    <Col xl={4} className={'d-flex align-items-center ' + props.orderright}>
                        <div>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-4`}>
                                {props.title}
                                {props.subtitle}
                            </h2>
                            {props.description}
                        </div>
                    </Col>
                </Row>
            </Container>    
        </>
    )
}
