export default function validateContact(values) {
    let errors = {};
    if (!values.first_name) {
        errors.first_name = " First name is required";
    }
    if (!values.last_name) {
        errors.last_name = "Last name is required";
    }
    if (!values.email) {
        errors.email = "Email is required";
    }
    if (!values.contact_no) {
        errors.contact_no = "Contact no. is required";
    }
    if (!values.message) {
        errors.message = "message is required";
    }
    return errors;
}