import Home from "../Views/Website/Home/Home";
import AboutUs from "../Views/Website/AboutUs/AboutUs";
import MarketPlace from "../Views/Website/MarketPlace/MarketPlace";
import ContactUs from "../Views/Website/ContactUs/ContactUs";
import Faq from "../Views/Website/Faq/Faq";
import TermsAndCondition from "../Views/Website/TermsAndCondition/TermsAndCondition";
import TermsAndConditionApp from "../Views/Website/TermsAndConditionApp/TermsAndConditionApp";
import PrivacyPolicy from "../Views/Website/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicyApp from "../Views/Website/PrivacyPolicyApp/PrivacyPolicyApp";
import Careers from "../Views/Website/Careers/Careers";
import Risks from "../Views/Website/Risks/Risks";
import Login from "../Views/Clients/Login/Login";

const websites = [
  { path: "/", exact: true, name: "Home", component: Login },
  { path: "/home", exact: true, name: "Home", component: Home },
  { path: "/careers", exact: true, name: "Careers", component: Careers },
  {
    path: "/privacy-policy",
    exact: true,
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/privacy-policy-app",
    exact: true,
    name: "PrivacyPolicyApp",
    component: PrivacyPolicyApp,
  },
  {
    path: "/terms-and-condition",
    exact: true,
    name: "TermsAndCondition",
    component: TermsAndCondition,
  },
  {
    path: "/terms-and-condition-app",
    exact: true,
    name: "TermsAndConditionApp",
    component: TermsAndConditionApp,
  },
  { path: "/Risks", exact: true, name: "Risks", component: Risks },
  { path: "/faq", exact: true, name: "Faq", component: Faq },
  { path: "/contact-us", exact: true, name: "ContactUs", component: ContactUs },
  {
    path: "/platform",
    exact: true,
    name: "MarketPlace",
    component: MarketPlace,
  },
  { path: "/about-us", exact: true, name: "AboutUs", component: AboutUs },
];

export default websites;
