import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary  } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';

const accordiandata = [
    { id: '5001', question: 'I submitted my registration, when will it be verified?', answer: 'Thanks for submitting your details. Our team will review it shortly and an email will be sent with a registration code to create login.' },
    { id: '5002', question: 'Who is allowed to register at AWRAAQ?', answer: 'AWRAAQ caters to Saudi registered brokerage firms only.' },
    { id: '5003', question: 'How can I change my password?', answer: 'After you have logged in to your portal, you may visit the change your password option in the account details section. Please follow the character type and limit requirement for setting a secure password. In case of login issues, kindly email at info@awraaq.com' },
    { id: '5004', question: 'Can I change my email address?', answer: 'Each account is registered with a unique email id. In case you would like to change your email address, kindly register for a new account.' },
    { id: '5005', question: 'Which country should I select?', answer: 'Select the home country of your brokerage firm.' },
    { id: '5006', question: 'What does ‘title’ mean?', answer: 'It is your position at the brokerage firms.' },
]

const accordiandataar = [
    { id: '5001', question: 'قمت بالتسجيل، متى سيتم التحقق منه؟ ', answer: 'شكرا للتسجيل معنا. سيقوم فريقنا بمراجعته قريبًا وسيتم إرسال بريد إلكتروني يحتوي على  رمز تسجيل لإنشاء تسجيل الدخول.    ' },
    { id: '5002', question: 'من المسموح له بالتسجبل في منصة أوراق؟', answer: 'تقدم منصة أوراق خدماتها لشركات الوساطة السعودية المسجلة فقط.' },
    { id: '5003', question: 'كيف يمكنني تغيير كلمة المرور الخاصة بي؟    ', answer: 'بعد تسجيل الدخول إلى البوابة الإلكترونية ، يمكنك الضغط على خيار تغيير كلمة المرور الموجود اسفل  تفاصيل الحساب. يرجى اتباع نوع الأحرف ومتطلبات الحد لإعداد كلمة مرور آمنة. في حالة وجود مشاكل في تسجيل الدخول ، يرجى إرسال بريد إلكتروني على info@awraaq.com    ' },
    { id: '5004', question: 'هل يمكنني تغيير عنوان البريد الإلكتروني؟    ', answer: 'يتم تسجيل كل حساب بـ بريد إلكتروني فريد. في حال رغبتك بتغيير عنوان البريد الإلكتروني ، يرجى التسجيل بواسطة بريد الكتروني مختلف للحصول على حساب جديد.    ' },
    { id: '5005', question: 'أي بلد يجب أن أختار؟    ', answer: 'حدد موطن شركة الوساطة الخاصة بك.' },
    { id: '5006', question: 'ماذا يعني "المنصب"؟    ', answer: 'يعنى بذلك منصبك في شركة الوساطة التي تعمل بها.' },
]

export default function RegistrationFaq() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const accordianArr = curLang === 'ar' ? accordiandataar: accordiandata;
    return (
        <div className="accordian-faq">
            {accordianArr.map( (data) => 
                <Accordion expanded={expanded === `panel_${data.id}`} onChange={handleChange(`panel_${data.id}`)} key={data.id}>
                    <AccordionSummary
                        expandIcon={expanded === `panel_${data.id}` ? <RemoveIcon /> : <AddIcon />}
                        aria-controls={`panel_${data.id}bh-content`}
                        id={`panel_${data.id}bh-header`}
                    >
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-20 faq-ar">{t('faqpage.q')}</p>  
                            <p className="mb-0 text-black fw-medium fs-20">{data.question}</p>  
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="d-flex">
                            <p className="mb-0 mr-2 text-black fw-medium fs-18 outer-space faq-ar"> {t('faqpage.a')}</p>  
                            <p className="mb-0 text-black fw-medium fs-18 outer-space">{data.answer}</p> 
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    )
}
