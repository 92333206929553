import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { GuestApi } from '../../../utils/api';
import useForm from '../../../useForm';
import { toast } from 'react-toastify';
import validate from '../../../validate/validateCareer';
import { useTranslation } from 'react-i18next';
import { createTheme } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import { create } from "jss";
import rtl from "jss-rtl";
const { REACT_APP_SITE_KEY } = process.env;

export default function CareerForm() {

    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    const [captchaCheck, setCaptchaCheck] = useState(false);
    const [thanksContain, setThanksContain] = useState(false);

    const handleCaptaChange = (val) => {
        setCaptchaCheck(val !== null);
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (acceptedFiles.length === 0) {
            formIsValid = false;
            errors["cv"] = "Cv is required.";
        }
        setErrors({ ...errors, errors });
        return formIsValid;
    }

    const submit = async () => {
        if (handleValidation()) {
            try {
                let newSelectedFile = '';
                if (acceptedFiles && acceptedFiles.length) {
                    const selectedFile = acceptedFiles[0];
                    newSelectedFile = selectedFile;
                }
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("email", values.email);
                formData.append("cv", newSelectedFile);
                const response = await GuestApi.post(`/website/career-form`, formData);
                const { status, data } = response;
                if (status === 201 && data.career !== undefined) {
                    setThanksContain(true);
                    setTimeout(function(){ 
                        setThanksContain(false);
                        setValues(formInputObj);
                    }, 5000);
                }
            } catch (e) {
                const { response } = e;
                if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                    setErrors(response.data.error); // if error from server side
                } else {
                    toast.error(`😱 Axios request failed: ${e}`);
                }
            }
        }
    }

    const formInputObj = {
        name: "",
        email: "",
        cv: ""
    };
    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(
        submit,
        validate,
        formInputObj
    );

    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
    const theme = createTheme({
        direction: "rtl"
    });

    return (
        curLang === 'ar' ? 
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                {
                    thanksContain ?
                    <>
                        <Form.Group as={Col} xs={12}>
                            <h3>شكرًا لتواصلك معنا ، سيتم التواصل بك قريبًا</h3>
                        </Form.Group>
                    </>     :
                    <>
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Form.Group as={Col} xs={12}>
                                    <TextField 
                                        label={t('careerpage.label1')} 
                                        variant="outlined" type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                    />
                                    {errors.name && <small className="text-danger">{errors.name}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('careerpage.label2')} 
                                        variant="outlined" 
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        <p className="fs-14 mb-0">{t('careerpage.input-p')} </p>
                                    </div>
                                    {errors.cv && <small className="text-danger">{errors.cv}</small>}

                                    <aside className="mt-2">
                                        <ul className="list-unstyled">{files}</ul>
                                    </aside>
                                </Form.Group>
                                <Form.Group as={Col} xs={12}>
                                    <ReCAPTCHA
                                        sitekey={REACT_APP_SITE_KEY}
                                        onChange={handleCaptaChange}
                                    />
                                </Form.Group>
                                <Col xs={12}>
                                    <Button disabled={!captchaCheck} variant="contained" color="primary" className="green-btnar px-4" type="submit">
                                        {t('careerpage.btn')} 
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </ThemeProvider>
        </StylesProvider>
        :
        <>
            {
                thanksContain ?
                <>
                    <Form.Group as={Col} xs={12}>
                        <h3>Thank you for contacting us, we will get back to you soon...</h3>
                    </Form.Group>
                </>     :
                <>
                    <Form onSubmit={handleSubmit} noValidate>
                        <Row>
                            <Form.Group as={Col} xs={12}>
                                <TextField 
                                    label={t('careerpage.label1')} 
                                    variant="outlined" type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <TextField 
                                    label={t('careerpage.label2')} 
                                    variant="outlined" 
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                {errors.email && <small className="text-danger">{errors.email}</small>}
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p className="fs-14 mb-0">{t('careerpage.input-p')} </p>
                                </div>
                                {errors.cv && <small className="text-danger">{errors.cv}</small>}

                                <aside className="mt-2">
                                    <ul className="list-unstyled">{files}</ul>
                                </aside>
                            </Form.Group>
                            <Form.Group as={Col} xs={12}>
                                <ReCAPTCHA
                                    sitekey={REACT_APP_SITE_KEY}
                                    onChange={handleCaptaChange}
                                />
                            </Form.Group>
                            <Col xs={12}>
                                <Button disabled={!captchaCheck} variant="contained" color="primary" className="green-btn px-4" type="submit">
                                    {t('careerpage.btn')} 
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </>
    )
}
