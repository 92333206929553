import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import NavbarMenu from '../../../Components/NavbarMenu';
import Footer from '../../../Components/Footer';
import RegistrationFaq from './RegistrationFaq';
import NegotiationRoomFaq from './NegotiationRoomFaq';
import MembersFaq from './MembersFaq';
import { useTranslation } from 'react-i18next';
import { blue } from '@material-ui/core/colors';


function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="tab-content">
            {children}
          </div>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

export default function Faq() {

    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className="p-0 faq2-bannerE">
                    <div className="hero contact-hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`apache ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase text-uppercase`}>{t('faqpage.title')}</h1>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>  

            <section className={`faq${curLang} py-5`}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold mb-5`}>
                                {t('faqpage.h2')}
                                <p className="fs-22 mb-0 mt-2">{t('faqpage.p1')} <a style={{color: blue}} href="mailto:business@awraaq.com"> business@awraaq.com </a>{t('faqpage.p2')} </p> 
                            
                            </h2>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs"
                                >
                                    <Tab label={t('faqpage.registration')} {...a11yProps(0)} />
                                    {/* <Tab label="Block Trade" {...a11yProps(3)} /> */}
                                    <Tab label={t('faqpage.negotiation-room')} {...a11yProps(1)} />
                                    <Tab label={t('faqpage.members')} {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <RegistrationFaq />   
                            </TabPanel>
                            {/* <TabPanel value={value} index={1}>
                                <BlockTradeFaq />
                            </TabPanel> */}
                            <TabPanel value={value} index={1}>
                                <NegotiationRoomFaq />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <MembersFaq />
                            </TabPanel>
                        </Col> 
                    </Row>
                </Container>
            </section>

            <Footer />  
        </div>
    )
}