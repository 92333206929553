import React, { useState, useMemo, Suspense, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import "./App.scss";
import "./websitestyle/style.scss";

import { UserContext } from "./UserContext";
import { ClientContext } from "./ClientContext";
import ScrollToTop from "./Components/ScrollToTop";
// import routes from './routes';
import website from "./routes/website";
import admin_guest from "./routes/admin_guest";
import client_guest from "./routes/client_guest";

import admin from "./routes/admin";
import institutional from "./routes/institutional";
import "react-toastify/dist/ReactToastify.css";
// const TheLayout = lazy(() => import('./containers/TheLayout'));
import { fireabseInit, onMessageListener } from "./utils/firebase";

function App() {
  let userLocalStorage = null;
  let clientLocalStorage = null;
  let adminRoutes = [...admin_guest];
  let clientRoutes = [];

  let allRoutes = [...admin_guest];

  if (localStorage.getItem("user_data") != null) {
    userLocalStorage = JSON.parse(localStorage.getItem("user_data"));
    const { client_type } = userLocalStorage.token;
    if (client_type === "Admin") {
      adminRoutes = [...adminRoutes, ...admin];
      allRoutes = [...adminRoutes];
    }
  }

  if (localStorage.getItem("client_data") != null) {
    clientLocalStorage = JSON.parse(localStorage.getItem("client_data"));
    const { client_type } = clientLocalStorage.token;
    if (client_type === "Institutional") {
      clientRoutes = [...institutional];
    }
    if (client_type === "Individual") {
      // clientRoutes = [...individual];
      clientRoutes = [...institutional];
    }

    allRoutes = [...clientRoutes];
  }

  const [user, setUser] = useState(userLocalStorage);
  const [client, setClient] = useState(clientLocalStorage);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const clientVal = useMemo(() => ({ client, setClient }), [client, setClient]);
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
  );

  function onNotification(payload) {
    const data = payload.notification;
    const notificationTitle = data.title;
    const notificationOptions = {
      body: data.body,
    };

    if (!("Notification" in window)) {
      console.log("Browser does not support system notification");
    } else if (Notification.permission === "granted") {
      new Notification(notificationTitle, notificationOptions);
    }
  }

  useEffect(() => {
    fireabseInit();
    onMessageListener(onNotification);
  }, []);

  return (
    <>
      <Router>
        <Suspense fallback={loading()}>
          <ScrollToTop />
          <Switch>
            {website.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              ) : null;
            })}
            {client_guest.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              ) : null;
            })}
            <UserContext.Provider value={value}>
              {allRoutes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={route.component}
                  />
                ) : null;
              })}
            </UserContext.Provider>
            <ClientContext.Provider value={clientVal}>
              {allRoutes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx + "a"}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={route.component}
                  />
                ) : null;
              })}
            </ClientContext.Provider>
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        draggable={false}
        position="top-center"
        transition={Flip}
        autoClose={3000}
        pauseOnHover={true}
      />
    </>
  );
}

export default App;
