import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavbarMenu from '../../../Components/NavbarMenu';
import OnlyContent from '../../../Components/OnlyContent';
import Footer from '../../../Components/Footer';
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';

    return (
        <div className={`website-body ${curLang}`}>
            <header>
                <NavbarMenu />
                <Container fluid className={`p-0 faq-bannerE${curLang === 'ar' ? 'Ar' : 'n'}`}>
                    <div className="hero contact-hero position-relative">
                        <Container>
                            <div className={`hero-text position-absolute text-center text-sm-${curLang === 'ar' ? 'right' : 'left'}`}>
                                <h1 className={`green ${curLang === 'ar' ? 'almarai' : 'montserrat'} font-weight-bold fs-60 text-uppercase`}>{t('aboutpage.title')}</h1>
                                <p className={`green fs-30 ${curLang === 'ar' ? 'almarai' : 'montserrat'} mb-0 font-weight-bold text-capitalize`}>{t('aboutpage.title-p')}</p>
                            </div>
                        </Container>
                    </div>
                </Container>
            </header>
            <section className={`pioneering-kingdom py-5 ${curLang === 'ar' && 'titlear'}`}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className={`title${curLang} fs-32 light-black fw-semi-bold`}>
                                {t('aboutpage.OnlyContent-title')}
                            </h2>
                        </Col>
                    </Row>
                </Container>
                <OnlyContent
                    title=""
                    content={
                        <>
                            <p className="content">{t('aboutpage.body-p1')}.</p>
                        </>
                    }
                    fullcontent={
                        <>
                            {
                                curLang === 'ar' ?
                                    <p className="content">بدأت فكرة أوراق عام ٢٠١٦ م انطلاقاً من رؤية استراتيجية مفادها أن التداول القائم على التكنولوجيا  والمدمج مع المجموعات الصحيحة من أدوات التداول الافتراضية والشبكات؛ سيسهم في إعادة تشكيل المشهد التجاري. تتمثل رؤيتنا في إنشاء تجارة تداول افتراضية لا حد لها، تجعل الصفقات الخاصة في متناول الجميع من خلال منصة مركزية موحدة.  هدفنا هو تعزيز سيولة التداول الافتراضي في سوق المال من خلال زيادة فعالية إدارة الصفقات الخاصة خارج السوق.  تجمع أوراق بين التجار والوسطاء ورأس المال، حيث تقدم المنصة تجربة تداول افتراضية آمنة و فعالة . لحجز مقعدك في مجموعة المتداولين الافتراضية الرائدة، قم بالتسجيل معنا اليوم! </p>
                                    :
                                    <p className="content">AWRAAQ'S foundation was laid in the year 2016 with a vision that tech enabled block trading incorporated with the right set of virtual trading tools and networking will reinvent the block trading landscape. Our vision is to create Boundless Virtual Block Trading; making block trade accessible virtually for everyone and anywhere from all walks of life. Our goal is to boost the capital market liquidity and transform people's lives by helping them make better financial decisions, just with a few clicks. AWRAAQ connects traders, brokers and capital by providing a superior virtual block trade experience and is leader in virtual block trade management. To secure a seat at the leading virtual conglomerate of traders, discover more by registering with us. We will assist you in becoming the future!</p>
                            }
                        </>
                    }
                />
            </section>
            <Footer />
        </div>
    )
}
