import { lazy } from "react";

const Login = lazy(() => import("../Views/Admin/Login/Login"));
const Dashboard = lazy(() => import("../Views/Admin/Dashboard/Dashboard"));
const InstitutionalDeals = lazy(() =>
  import("../Views/Admin/Deals/InstitutionalDeals/InstitutionalDeals")
);
const PendingDealsOfferAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/PendingDeals/PendingDealsOfferAction"
  )
);
const PendingDealsOrderAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/PendingDeals/PendingDealsOrderAction"
  )
);
const CancelledDealsOrderAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/CancelledDeals/CancelledDealsAction"
  )
);
const ActiveDealsAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/ActiveDeals/ActiveDealsAction"
  )
);
const WorkingDealsAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/WorkingDeals/WorkingDealsAction"
  )
);
const CompletedDealsAction = lazy(() =>
  import(
    "../Views/Admin/Deals/InstitutionalDeals/CompletedDeals/CompletedDealsAction"
  )
);
const IndividualDeals = lazy(() =>
  import("../Views/Admin/Deals/IndividualDeals/IndividualDeals")
);
const IndividualPendingDealsOrderAction = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/PendingDeals/PendingDealsOrderAction"
  )
);
const PendingOffersAction = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/PendingOffers/PendingOffersAction"
  )
);
const ActiveDealsActionIndividual = lazy(() =>
  import("../Views/Admin/Deals/IndividualDeals/ActiveDeals/ActiveDealsAction")
);
const IndividualActiveOffers = lazy(() =>
  import("../Views/Admin/Deals/IndividualDeals/ActiveDeals/ActiveOffers")
);
const IndividualActiveOfferDetails = lazy(() =>
  import("../Views/Admin/Deals/IndividualDeals/ActiveDeals/ActiveOfferDetail")
);
const IndividualCompletedOfferDetails = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/CompletedDeals/CompletedOfferDetails"
  )
);
const IndividualCompletedOrderDetails = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/CompletedDeals/CompletedOrderDetails"
  )
);
const IndividualWorkingOffers = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/WorkingDeals/WorkingDealsActionMain"
  )
);
const IndividualWorkingOrderDetails = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/WorkingDeals/WorkingOrderDetails"
  )
);
const IndividualWorkingOfferDetails = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/WorkingDeals/WorkingOfferDetails"
  )
);
const WorkingDealsActionSub = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/WorkingDeals/WorkingDealsActionSub"
  )
);
const CompletedDealsActionIndividual = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/CompletedDeals/CompletedDealsAction"
  )
);
const CancelledDealsAction = lazy(() =>
  import(
    "../Views/Admin/Deals/IndividualDeals/CancelledDeals/CancelledDealsAction"
  )
);
const ExpiredDeals = lazy(() =>
  import("../Views/Admin/Deals/IndividualDeals/ExpiredDeals/ExpiredOffersTab")
);

// Master
const CountryMaster = lazy(() =>
  import("../Views/Admin/Master/Country/CountryMaster")
);
const MarketMaster = lazy(() =>
  import("../Views/Admin/Master/MarketMaster/MarketMaster")
);
const CapitalMarket = lazy(() =>
  import("../Views/Admin/Master/CapitalMarket/CapitalMarketMaster")
);
const TierMaster = lazy(() => import("../Views/Admin/Master/Tier/TierMaster"));
const PackageMaster = lazy(() =>
  import("../Views/Admin/Master/Package/PackageMaster")
);
const CancellationReason = lazy(() =>
  import("../Views/Admin/Master/CancellationReason/CancellationReason")
);
const CompanyMaster = lazy(() =>
  import("../Views/Admin/Master/CompanyMaster/CompanyMaster")
);
const CurrencyMaster = lazy(() =>
  import("../Views/Admin/Master/Currency/CurrencyMaster")
);
const BrokerageFirm = lazy(() =>
  import("../Views/Admin/Master/BrokerageFirm/BrokerageFirm")
);
const SecurityType = lazy(() =>
  import("../Views/Admin/Master/SecurityType/SecurityType")
);
const User = lazy(() => import("../Views/Admin/User/User"));
const Role = lazy(() => import("../Views/Admin/Role/Role"));
const AddClient = lazy(() => import("../Views/Admin/Clients/AddClient"));
const Clients = lazy(() => import("../Views/Admin/Clients/Clients"));
const NewClientRequestAction = lazy(() =>
  import("../Views/Admin/Clients/NewClientRequest/NewClientRequestAction")
);
const InProcessClientRequestAction = lazy(() =>
  import(
    "../Views/Admin/Clients/InProcessClientRequest/InProcessClientRequestAction"
  )
);
const ActiveClientAction = lazy(() =>
  import("../Views/Admin/Clients/ActiveClient/ActiveClientAction")
);
const RejectedClientAction = lazy(() =>
  import("../Views/Admin/Clients/RejectedClient/RejectedClientAction")
);
const Transaction = lazy(() =>
  import("../Views/Admin/Transaction/Transaction")
);
const CompletedTransactionAction = lazy(() =>
  import(
    "../Views/Admin/Transaction/CompletedTransaction/CompletedTransactionAction"
  )
);
const PendingTransactionAction = lazy(() =>
  import(
    "../Views/Admin/Transaction/PendingTransaction/PendingTransactionAction"
  )
);

const admin = [
  { path: "/admin", exact: true, name: "Login", component: Login },
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/institutional-deals/deals",
    exact: true,
    name: "Institutional Deals",
    component: InstitutionalDeals,
  },
  {
    path: "/admin/pending-deals-offer-action",
    exact: true,
    name: "Pending Deals Offer Action",
    component: PendingDealsOfferAction,
  },
  {
    path: "/admin/pending-deals-order-action",
    exact: true,
    name: "Pending Deals Order Action",
    component: PendingDealsOrderAction,
  },
  {
    path: "/admin/cancelled-deals-action",
    exact: true,
    name: "Pending Deals Order Action",
    component: CancelledDealsOrderAction,
  },
  {
    path: "/admin/active-deals-action/",
    exact: true,
    name: "Active Deals Action",
    component: ActiveDealsAction,
  },
  {
    path: "/admin/working-deals-action/",
    exact: true,
    name: "Working Deals Action",
    component: WorkingDealsAction,
  },
  {
    path: "/admin/completed-deals-action/",
    exact: true,
    name: "Completed Deals Action",
    component: CompletedDealsAction,
  },
  {
    path: "/admin/individual-deals/deals",
    exact: true,
    name: "Individual Deals",
    component: IndividualDeals,
  },
  {
    path: "/admin/individual-pending-deals-order-action/",
    exact: true,
    name: "Pending Deals Order Action",
    component: IndividualPendingDealsOrderAction,
  },
  {
    path: "/admin/individual-pending-offers-action/:id",
    exact: true,
    name: "Pending Offers Action",
    component: PendingOffersAction,
  },
  {
    path: "/admin/individual-active-order-details/:id",
    exact: true,
    name: "Active Deals Action Individual",
    component: ActiveDealsActionIndividual,
  },
  {
    path: "/admin/individual-active-offers/:id",
    exact: true,
    name: "Active Offers",
    component: IndividualActiveOffers,
  },
  {
    path: "/admin/individual-active-offer-details/:id",
    exact: true,
    name: "Active Offer Details",
    component: IndividualActiveOfferDetails,
  },
  {
    path: "/admin/individual-working-offers/",
    exact: true,
    name: "Individual Working Offers",
    component: IndividualWorkingOffers,
  },
  {
    path: "/admin/individual-working-order-details/",
    exact: true,
    name: "Individual Working Order Details",
    component: IndividualWorkingOrderDetails,
  },
  {
    path: "/admin/individual-working-offer-details",
    exact: true,
    name: "Individual Working Offer Details",
    component: IndividualWorkingOfferDetails,
  },
  {
    path: "/admin/working-deals-action-sub",
    exact: true,
    name: "Working Deals Action Sub",
    component: WorkingDealsActionSub,
  },
  {
    path: "/admin/individual-completed-offers/:id",
    exact: true,
    name: "Completed Deals Action Individual",
    component: CompletedDealsActionIndividual,
  },
  {
    path: "/admin/individual-cancelled-offer-action/:id",
    exact: true,
    name: "Cancelled Deals Action",
    component: CancelledDealsAction,
  },
  {
    path: "/admin/individual-completed-order-details/:id",
    exact: true,
    name: "Completed Order Details",
    component: IndividualCompletedOrderDetails,
  },
  {
    path: "/admin/individual-completed-offer-details/:id",
    exact: true,
    name: "Completed Offer Details",
    component: IndividualCompletedOfferDetails,
  },
  {
    path: "/admin/country",
    exact: true,
    name: "Country",
    component: CountryMaster,
  },
  {
    path: "/admin/capital-market",
    exact: true,
    name: "Capital Market",
    component: CapitalMarket,
  },
  {
    path: "/admin/tier",
    exact: true,
    name: "TierMaster",
    component: TierMaster,
  },
  {
    path: "/admin/package",
    exact: true,
    name: "PackageMaster",
    component: PackageMaster,
  },
  {
    path: "/admin/company",
    exact: true,
    name: "Campany",
    component: CompanyMaster,
  },
  {
    path: "/admin/brokerage-firm",
    exact: true,
    name: "BrokerageFirm",
    component: BrokerageFirm,
  },
  {
    path: "/admin/security-type",
    exact: true,
    name: "SecurityType",
    component: SecurityType,
  },
  {
    path: "/admin/cancellation-reason",
    exact: true,
    name: "Cancellation Reason",
    component: CancellationReason,
  },
  {
    path: "/admin/market",
    exact: true,
    name: "Market",
    component: MarketMaster,
  },
  {
    path: "/admin/currency",
    exact: true,
    name: "Currency",
    component: CurrencyMaster,
  },
  { path: "/admin/user", exact: true, name: "User", component: User },
  { path: "/admin/role", exact: true, name: "Role", component: Role },
  {
    path: "/admin/clients/clients-tab",
    exact: true,
    name: "Client",
    component: Clients,
  },
  {
    path: "/admin/add-client/",
    exact: true,
    name: "Add Client",
    component: AddClient,
  },
  {
    path: "/admin/new-client-request-action/",
    exact: true,
    name: "NewClientRequestAction",
    component: NewClientRequestAction,
  },
  {
    path: "/admin/in-process-client-request-action/",
    exact: true,
    name: "In Process Client Request Action",
    component: InProcessClientRequestAction,
  },
  {
    path: "/admin/active-client-action/",
    exact: true,
    name: "Active Client Action",
    component: ActiveClientAction,
  },
  {
    path: "/admin/rejected-client-action/",
    exact: true,
    name: "Rejected Client Action",
    component: RejectedClientAction,
  },
  {
    path: "/admin/transaction/transactions-tab",
    exact: true,
    name: "Transaction",
    component: Transaction,
  },
  {
    path: "/admin/completed-transaction-action/",
    exact: true,
    name: "Completed Transaction Action",
    component: CompletedTransactionAction,
  },
  {
    path: "/admin/pending-transaction-action/",
    exact: true,
    name: "Pending Transaction Action",
    component: PendingTransactionAction,
  },
  {
    path: "/admin/expired-orders-tab/:id",
    exact: true,
    name: "Expired Orders Tab",
    component: ExpiredDeals,
  },
];

export default admin;
