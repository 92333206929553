import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import awraaq from '../assets/img/awraaq2.png'

export default function NavbarMenu() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';
    return (
        <>
            <Navbar bg="white" expand="lg" fixed="top">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={awraaq} alt="Awraaq" className="img-fluid" style={{ width: 219 }} />
                        </Link>
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={setHidden}> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="bar top"></span>
                        <span className="bar middle"></span>
                        <span className="bar bottom"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className={`ml-auto${curLang} mr-3${curLang}`}>
                            <NavLink activeClassName="active" className="nav-link" to="/about-us">{t('homepage.about-us')}</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/platform/">{t('homepage.platform')}</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/contact-us">{t('homepage.contact-us')}</NavLink>
                            <NavLink activeClassName="active" className="nav-link" to="/careers">{t('homepage.careers')}</NavLink>
                            <NavLink className="nav-link nav-btn" target="_blank" to="/login">{t('homepage.login')}</NavLink>
                            <NavLink className="nav-link nav-btn" target="_blank" to="/register">{t('homepage.register')}</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                    <div>
                        {
                            (curLang === 'ar') ?
                                <button className="btn btn-link shadow-none text-decoration-none lang-btn" onClick={() => changeLanguage('en')}>English</button>
                                :
                                <button className="btn btn-link shadow-none text-decoration-none lang-btn" style={{ fontFamily: "Almarai" }} onClick={() => changeLanguage('ar')}>عربي</button>
                        }
                    </div>
                </Container>
            </Navbar>
        </>
    )
}