import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Form } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { GuestApi } from '../../../utils/api';
import useForm from '../../../useForm';
import { toast } from 'react-toastify';
import validate from '../../../validate/validateContact';
import { useTranslation } from 'react-i18next';
import { createTheme } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import { create } from "jss";
import rtl from "jss-rtl";

const { REACT_APP_SITE_KEY } = process.env;

export default function ContactForm() {
    const { t } = useTranslation();
    let curLang = localStorage.getItem('i18nextLng') === 'en' ? '' : 'ar';
    const [captchaCheck, setCaptchaCheck] = useState(false);
    const [thanksContain, setThanksContain] = useState(false);

    const handleCaptaChange = (val) => {
        setCaptchaCheck(val !== null);
    }

    const submit = async () => {
        try {
            const response = await GuestApi.post(`/website/contact-form`, values);
            const { status, data } = response;
            if (status === 201 && data.contact !== undefined) {
                setThanksContain(true);
                setTimeout(function(){ 
                    setThanksContain(false);
                    setValues(formInputObj);
                }, 5000);
            }
        } catch (e) {
            const { response } = e;
            if (response !== undefined && Object.keys(response.data).length && response.data.error !== undefined) {
                setErrors(response.data.error); // if error from server side
            } else {
                toast.error(`😱 Axios request failed: ${e}`);
            }
        }
    }

    const formInputObj = {
        first_name: "",
        last_name: "",
        email: "",
        contact_no: "",
        message: "",
    };
    const { handleChange, handleSubmit, values, errors, setErrors, setValues } = useForm(
        submit,
        validate,
        formInputObj
    );

    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
    const theme = createTheme({
        direction: "rtl"
    });

    return (
        curLang === 'ar' ? 
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                {
                    thanksContain ?
                    <>
                        <Form.Group as={Col} xs={12}>
                        <h3>شكرًا لتواصلك معنا ، سيتم التواصل بك قريبًا</h3>
                        </Form.Group>
                    </>     :
                    <>
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label1')}  
                                        variant="outlined"
                                        type="text"
                                        name="first_name"
                                        onChange={handleChange}
                                        value={values.first_name}
                                    />
                                    {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label2')} 
                                        variant="outlined"
                                        type="text"
                                        name="last_name"
                                        onChange={handleChange}
                                        value={values.last_name}
                                    />
                                    {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                    label={t('contactuspage.label3')} 
                                        variant="outlined" 
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label4')} 
                                        variant="outlined"
                                        type="text"
                                        name="contact_no"
                                        onChange={handleChange}
                                        value={values.contact_no}
                                    />
                                    {errors.contact_no && <small className="text-danger">{errors.contact_no}</small>}
                                </Form.Group>
                                <Form.Group as={Col} xs={12}>
                                    <TextField 
                                        label={t('contactuspage.label5')}
                                        variant="outlined" 
                                        multiline
                                        rows={4}
                                        name="message"
                                        onChange={handleChange}
                                        value={values.message}
                                    />
                                    {errors.message && <small className="text-danger">{errors.message}</small>}
                                </Form.Group>
                                <Form.Group as={Col} xs={12}>
                                    <ReCAPTCHA
                                        sitekey={REACT_APP_SITE_KEY}
                                        onChange={handleCaptaChange}
                                    />
                                </Form.Group>
                                <Col xs={12}>
                                    <Button disabled={!captchaCheck} variant="contained" color="primary" className="green-btnar px-4" type="submit">
                                        {t('contactuspage.btn')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
            </ThemeProvider>
        </StylesProvider>
        :
        <>
            {
                thanksContain ?
                    <>
                        <Form.Group as={Col} xs={12}>
                        <h3>Thank you for contacting us, we will get back to you soon...</h3>
                        </Form.Group>
                    </>     :
                    <>
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label1')}  
                                        variant="outlined"
                                        type="text"
                                        name="first_name"
                                        onChange={handleChange}
                                        value={values.first_name}
                                    />
                                    {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label2')} 
                                        variant="outlined"
                                        type="text"
                                        name="last_name"
                                        onChange={handleChange}
                                        value={values.last_name}
                                    />
                                    {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                    label={t('contactuspage.label3')} 
                                        variant="outlined" 
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <TextField 
                                        label={t('contactuspage.label4')} 
                                        variant="outlined"
                                        type="text"
                                        name="contact_no"
                                        onChange={handleChange}
                                        value={values.contact_no}
                                    />
                                    {errors.contact_no && <small className="text-danger">{errors.contact_no}</small>}
                                </Form.Group>
                                <Form.Group as={Col} xs={12}>
                                    <TextField 
                                        label={t('contactuspage.label5')}
                                        variant="outlined" 
                                        multiline
                                        rows={4}
                                        name="message"
                                        onChange={handleChange}
                                        value={values.message}
                                    />
                                    {errors.message && <small className="text-danger">{errors.message}</small>}
                                </Form.Group>
                                <Form.Group as={Col} xs={12}>
                                    <ReCAPTCHA
                                        sitekey={REACT_APP_SITE_KEY}
                                        onChange={handleCaptaChange}
                                    />
                                </Form.Group>
                                <Col xs={12}>
                                    <Button disabled={!captchaCheck} variant="contained" color="primary" className="green-btn px-4" type="submit">
                                        {t('contactuspage.btn')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
            }
        </>
    )
}
